<template>
  <div
    id="secondaryUserModal"
    aria-hidden="true"
    aria-labelledby="staticBackdropLabel"
    class="modal fade"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
  >
    <div id="modal-dialog-Secondary" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Previous Passenger</h5>

          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <p class="generalTitle mb-2">
                <small>{{ generalDetails.secondTableCaption }}</small>
              </p>
            </div>
            <div class="col-lg-12 col-sm-12">
              <table class="table table-borderless mt-2">
                <thead>
                  <tr>
                    <th class="opacity-50" scope="col">
                      {{ generalDetails.firstTableHeader }}
                    </th>
                    <th class="opacity-50" scope="col">
                      {{ generalDetails.secondTableHeader }}
                    </th>
                    <th class="opacity-50" scope="col">
                      {{ generalDetails.thirdTableHeader }}
                    </th>
                    <th class="opacity-50" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      secondaryPassenger, index
                    ) of secondaryPassengerDetails"
                    :key="secondaryPassenger.passangerID"
                    class="border-bottom"
                  >
                    <td data-label="Passenger Name" scope="row">
                      {{
                        secondaryPassenger.passangerFirstName +
                        " " +
                        secondaryPassenger.passangerLastName
                      }}
                    </td>
                    <td data-label="Contact">
                      {{ secondaryPassenger.passangerContactNumber }}
                    </td>
                    <td data-label="Passport Number">
                      {{ secondaryPassenger.passangerPassportNumber }}
                    </td>
                    <td class="text-lg-end">
                      <button
                        class="btn tblBtnAddPassenger"
                        type="button"
                        @click="
                          addSecondaryPassengerToLocalStorage(index, $event)
                        "
                      >
                        <img
                          :src="addSecondaryPassengerButton"
                          alt=""
                          class="tblBtnAddPassengerImage"
                        />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-end modal-footer">
          <button
            id="btnDone"
            aria-label="Close"
            class="btn btnYes"
            data-bs-dismiss="modal"
            type="button"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import addSecondaryPassengerButton from "../../../assets/Icons/addButton.svg";
import checkedImg from "../../../assets/Icons/checkedIcon.svg";
import vuex from "vuex";
import GeneralDetailsService from "../../../services/GeneralDetailsService";

const STORAGE_KEY_02 = "secondaryPassenger";

export default {
  name: "SecondaryUsers",
  data() {
    return {
      addSecondaryPassengerButton,
      checkedImg,
      generalDetails: this.$store.getters.getGeneralDetailsLabel,
      secondaryPassengerDetails: [],
      currentSecPassenger: [],
    };
  },
  async mounted() {
    // await axios
    //   .get(
    //     "http://login.emerge.lk:9090/MyPorterAPI/api/Passanger/GetSecondryPassangers"
    //   )
    //   .then((response) => {
    //     this.secondaryPassengerDetails = response.data;
    //   });
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser) {
      this.getSecondaryPassenger(currentUser.email);
    }
  },
  methods: {
    ...vuex.mapActions(["setSecondaryDetails"]),
    getSecondaryPassenger(email) {
      GeneralDetailsService.getSecondaryPassenger(email).then((response) => {
        if (response) {
          this.secondaryPassengerDetails = response.data;
        }
      });
    },
    addSecondaryPassengerToLocalStorage(id, event) {
      // console.log(event.path[0])
      event.path[0].src = this.checkedImg;
      // console.log(event.path[0].src);

      const btnList = document.getElementsByClassName("tblBtnAddPassenger");
      btnList[id].disabled = true;
      // console.log(btnList[id].children[0]);

      if (this.secondaryPassengerDetails !== null) {
        // console.log(
        //   "secondaryPassengerDetails",
        //   this.secondaryPassengerDetails
        // );
        const currentSecondaryPassengers = this.secondaryPassengerDetails[id];
        // currentSecondaryPassengers.push(this.secondaryPassengerDetails[id]);
        this.currentSecPassenger = currentSecondaryPassengers;
        this.setSecondaryDetails(currentSecondaryPassengers);
      } else {
        this.currentSecPassenger.push(this.secondaryPassengerDetails[id]);
        this.setSecondaryDetails(this.currentSecPassenger);
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop.show {
  opacity: 0 !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0 !important;
  width: 100vw;
  height: 100vh;
}

.tblBtnAddPassengerImage {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}

.tblBtnAddPassenger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 25px;
  border-radius: 100%;
  background-color: #e5efe6;
  margin-bottom: 2px;
}

.btn-close {
  border-radius: 100%;
}

.modal-content {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 10px 8px #e5e5e5;
}

.btnYes {
  width: 100px;
  background: #000080;
  color: #ffffff;
  border-radius: 10px;
}

.modal-footer {
  border-top: none;
}
</style>
