<template>
  <div id="loading" v-show="springLoader">
    <DoubleBounce></DoubleBounce>
  </div>
  <div id="pickUpLocationSectionDiv" class="d-none">
    <div
      id="pickupLocationSec"
      class="row d-flex justify-content-center align-item-center mt-2 mb-3"
    >
      <SectionHeader :header-title="pickupLocation" />
    </div>

    <div class="container-fluid">
      <form id="pickUpLocationForm" :dir="!isLanguageEN ? 'rtl' : ''">
        <div class="row d-flex justify-content-center align-item-center mx-0">
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50 mb-1 mb-sm-0" for="location">{{
                pickupLocation.location
              }}</label
              ><label class="text-danger">*</label>
              <div class="d-flex flex-row justify-content-between">
                <input
                  style="width: 88% !important"
                  id="location"
                  v-model="location"
                  aria-describedby="locationError"
                  :class="`form-control border-1 ${
                    errors.location ? 'is-invalid' : ''
                  }`"
                  type="text"
                  required
                  v-on="
                    isMobile()
                      ? { blur: locatorButtonPressed }
                      : { click: locatorButtonPressed }
                  "
                  @keyup="errors.location = ''"
                  ref="location"
                />
                <button
                  type="button"
                  class="btn btn-Icon-location"
                  @click="setCurrentLocation"
                >
                  <img
                    :src="currentLocationImage"
                    alt="currentLocationIcon"
                    width="16"
                  />
                </button>
              </div>
              <!-- <img :src="searchIcon" alt="" class="searchLocation" /> -->
              <small
                id="locationError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.location }}</small
              >
              <label class="label-front">
                <i
                  ><i class="fas fa-info-circle"></i>
                  {{ pickupLocation.pickupHint }}
                </i>
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50 mb-1" for="remark">{{
                pickupLocation.remark
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="remark"
                v-model="remark"
                aria-describedby="remarkError"
                :class="`form-control border-1 ${
                  errors.remark ? 'is-invalid' : ''
                }`"
                type="text"
                @keyup="formatLandmark"
              />
              <small
                id="remarkError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.remark }}</small
              >
            </div>
          </div>

          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50 mb-1" for="picUpDate">{{
                pickupLocation.pickup_Date_Time
              }}</label
              ><label class="text-danger">*</label>

              <VueCtkDateTimePicker
                id="dropOffDate"
                noLabel="true"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                color="#000080"
                v-model="pickup_Date"
                :min-date="moment().format('YYYY-MM-DD')"
                :max-date="moment(this.ValidDropOffDate).format('YYYY-MM-DD')"
                @click="setValidateForPickupDate()"
                @validate="setValidateForPickupDate()"
                noButtonNow="disabled"
                style="border-radius: 13px"
              />
              <small
                id="picUpDateError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.pickUpdate }}</small
              >
              <label class="label-front">
                <i
                  ><i class="fas fa-info-circle"></i>
                  {{ pickupLocation.minimumDateTime }}
                  {{
                    moment(this.ValidPickupDateTime).format("YYYY-MM-DD HH:mm")
                  }}
                  {{ pickupLocation.maxDateTime }}
                  {{ moment(this.ValidDropOffDate).format("YYYY-MM-DD HH:mm") }}
                </i>
              </label>
            </div>
          </div>
          <!--
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50 mb-1" for="pickUptime">{{
                pickupLocation.pickup_Time
              }}</label
              ><label class="text-danger">*</label>

              <VueCtkDateTimePicker
                only-time="true"
                noLabel="true"
                format="HH:mm"
                formatted="HH:mm"
                color="#006203"
                v-model="pickup_Time"
                @click="errors.dropOffTime = ''"
                @validate="setValidatePickupTime()"
                style="border-radius: 13px"
              />
              <small
                id="pickUptimeError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.pickUpTime }}</small
              >
            </div>
          </div> 
         !-->

          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50 mb-1" for="takerName">{{
                pickupLocation.care_Taker_Name
              }}</label>
              <input
                id="takerName"
                v-model="care_Taker_Name"
                aria-describedby="takerNameError"
                :class="`form-control border-1 ${
                  errors.carTakerName ? 'is-invalid' : ''
                }`"
                type="text"
                @keyup="formatCareTakerName"
              />
              <small
                id="takerNameError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.carTakerName }}</small
              >
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50 mb-1" for="takerPhone">{{
                pickupLocation.Care_Taker_Contact_Number
              }}</label>
              <input
                id="takerPhone"
                v-model="care_Taker_Contact_Number"
                aria-describedby="takerPhoneError"
                :class="`form-control border-1 ${
                  errors.care_Taker_Contact_Number ? 'is-invalid' : ''
                }`"
                type="text"
                @keyup="checkCarTakerTelephone($event)"
              />
              <small
                id="takerPhoneError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.care_Taker_Contact_Number }}</small
              >
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0"></div>
          <div class="col-md-12 mx-0">
            <div class="form-group">
              <div class="col-10 offset-lg-0 mt-3 text-bold">
                <span id="distance"></span>
              </div>
              <div id="mapDiv" class="mapDiv" ref="mapDiv"></div>
            </div>
          </div>
        </div>
        <!-- <ButtonList
          :save="addPickUpLocation"
          :next="pickupLocation.nextStep"
          :previous="pickupLocation.previous"
          :buttonName="buttonName"
        /> -->
        <div class="row mt-4 pb-lg-4 mx-0">
          <div
            class="
              col-6
              row
              flex-row
              d-flex
              justify-content-lg-end
              justify-content-md-end
              justify-content-sm-center
              ps-0
              mx-0
            "
          >
            <div
              class="
                form-group
                w-100
                row
                flex-row
                d-flex
                justify-content-lg-end
                justify-content-md-end
                justify-content-sm-center
                mx-0
              "
            >
              <button
                type="button"
                class="btn btn-previous"
                @click="previousView"
              >
                Previous
              </button>
            </div>
          </div>
          <div
            class="
              col-6
              row
              flex-row
              d-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-center
              ps-0 ps-md-3 ps-lg-3
              mx-0
            "
          >
            <div
              class="
                form-group
                w-100
                row
                flex-row
                d-flex
                justify-content-lg-start
                justify-content-md-start
                justify-content-sm-center
                mx-0
              "
            >
              <button
                type="button"
                class="btn btn-next"
                @click="addPickUpLocation"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SectionHeader from "../../../common/components/booking/SectionHeader";
import searchIcon from "../../../assets/Icons/search.svg";
import ButtonList from "../../../common/components/booking/ButtonList";
import Map from "../../../common/components/booking/Map";
import validatePickUplocationDetailsInput from "../../../validations/PickUplocation/PickUpLocationValidation";
import axios from "axios";
import VueSweetalert2 from "sweetalert2";
import vuex from "vuex";
import "jquery-clock-timepicker";
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import DropOffLocationService from "../../../services/DropOffLocationService";
import pickupLocationIcon from "../../../assets/Icons/pickuplocation.png";
import haversine from "haversine-distance";
import currentLocationImage from "../../../assets/Icons/aim.svg";
import { DoubleBounce } from "vue-loading-spinner";
import LocalStorageService from "../../../services/localStorageService";

export default {
  name: "PickupLocationSection",
  components: {
    Map,
    ButtonList,
    SectionHeader,
    VueCtkDateTimePicker,
    DoubleBounce,
  },
  data: function () {
    return {
      currentLocationImage,
      searchIcon,
      pickupLocationIcon,
      pickupLocation: this.$store.getters.getPickupLocationDetails,
      buttonName: "Next Step",
      location: "",
      remark: "",
      pickup_Date: new Date().toISOString().split("T")[0],
      pickup_Time: "",
      care_Taker_Name: "",
      care_Taker_Contact_Number: "",
      details: {},
      address: "",
      mapError: "",
      errors: {},
      primaryPassengerDetails: [],
      latitudes: "",
      longitudes: "",
      dropLocationlatitudes: "",
      dropLocationlongitudes: "",
      selectLocation: false,
      minPickUpTime: "",
      maxPickUpDate: "",
      savedDropOffLocation: {},
      dropOffLocationData: [],
      distanceVal: 0,
      ValidPickupDateTime: "",
      ValidMaxPickupDateTime: "",
      TimeValid: true,
      ValidDropOffDate: "",
      isLanguageEN: true,
      DropOffDateTime: "",
      isLocationSelected: false,
      springLoader: false,
      dropOffLocationOperatingMaximumPortDistance: 0,
    };
  },

  computed: {
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapGetters(["getDropOffLocationInputDetails"]),
  },

  watch: {
    getPrimaryPassengerDetails: {
      handler: function (val) {
        this.setPicUpTime();
      },
      deep: true,
    },

    // getDropOffLocationInputDetails
    getDropOffLocationInputDetails: {
      handler: function (val) {
        this.setPicUpTime();
        this.savedDropOffLocation =
          this.$store.getters.getDropOffLocationInputDetails;

        const lat =
          this.$store.getters.getDropOffLocationInputDetails.latitudes;
        const long =
          this.$store.getters.getDropOffLocationInputDetails.longitudes;
        const map = new google.maps.Map(document.getElementById("mapDiv"), {
          zoom: 10,
          center: new google.maps.LatLng(lat, long),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        const cityCircle = new google.maps.Circle({
          strokeColor: "#006203",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#006203",
          fillOpacity: 0.35,
          map,
          center: new google.maps.LatLng(lat, long),
          radius: this.operatingMaximumPortDistance,
        });
        new google.maps.Marker({
          position: new google.maps.LatLng(lat, long),
          map: map,
        });
      },
      deep: true,
    },
  },

  mounted() {
    $(".timePicker").clockTimePicker({
      duration: false,
      durationNegative: true,
      precision: 5,
      i18n: {
        cancelButton: "Canel",
      },
      onAdjust: function (newVal) {
        this.pickup_Time = newVal;
      },
    });

    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
    const BokkingRequestDetailsList = JSON.parse(
      sessionStorage.getItem("BRDL")
    );

    if (
      BokkingRequestDetailsList != null &&
      BokkingRequestDetailsList.length != 0
    ) {
      this.setDropOFFLocationDetails(
        BokkingRequestDetailsList[0].dropOffLocations
      );
    }

    if (
      BokkingRequestDetailsList != null &&
      BokkingRequestDetailsList.length != 0 &&
      BokkingRequestDetailsList[0].picUpLocations != null
    ) {
      // this.$store.dispatch(
      //   "setPickupLocations",
      //   BokkingRequestDetailsList[0].picUpLocations
      // );
      this.location = BokkingRequestDetailsList[0].picUpLocations.location;
      this.remark = BokkingRequestDetailsList[0].picUpLocations.remark;

      this.distanceVal =
        BokkingRequestDetailsList[0].picUpLocations.distanceVal;
      this.dropOffLocationOperatingMaximumPortDistance =
        BokkingRequestDetailsList[0].dropOffLocations.operatingMaximumPortDistance;
      this.dropLocationlatitudes =
        BokkingRequestDetailsList[0].dropOffLocations.latitudes;
      this.dropLocationlongitudes =
        BokkingRequestDetailsList[0].dropOffLocations.longitudes;
      this.latitudes = BokkingRequestDetailsList[0].picUpLocations.latitudes;
      this.longitudes = BokkingRequestDetailsList[0].picUpLocations.longitudes;
      this.care_Taker_Name =
        BokkingRequestDetailsList[0].picUpLocations.care_Taker_Name;
      this.care_Taker_Contact_Number =
        BokkingRequestDetailsList[0].picUpLocations.care_Taker_Contact_Number;
      this.getDataFromLocalStorageShowUserLocationOnTheMap(
        this.latitudes,
        this.longitudes
      );

      this.calculateDistance(this.latitudes, this.longitudes);
    }

    const currentLocationId = sessionStorage.getItem("CID");

    if (currentLocationId) {
      if (parseInt(currentLocationId) == 2) {
        document
          .getElementById("dropOffLocationSection")
          .classList.add("d-none");
        document
          .getElementById("dropOffLocationSection")
          .classList.remove("d-block");
        this.springLoader = true;
        setTimeout(() => {
          this.springLoader = false;
          document
            .getElementById("pickUpLocationSectionDiv")
            .classList.remove("d-none");
          document
            .getElementById("pickUpLocationSectionDiv")
            .classList.add("d-block");
        }, 800);
      }
    }

    // DropOffLocationService.getDropOffLocations().then((res) => {
    //   this.dropOffLocationData = res.data[2];

    //   if (navigator.geolocation) {
    //     const lat = res.data[0].latitudes;
    //     const long = res.data[0].longitudes;
    //     const map = new google.maps.Map(document.getElementById("mapDiv"), {
    //       zoom: 5,
    //       center: new google.maps.LatLng(lat, long),
    //       mapTypeId: google.maps.MapTypeId.ROADMAP,
    //     });
    //   }
    // });
  },
  created() {
    const savedPickUpLocatiomData = this.$store.getters.pickupLocation;

    if (savedPickUpLocatiomData === null) {
      this.location = "";
      this.remark = "";
      this.pickup_Date = "";
      this.pickup_Time = new Date().toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
      this.care_Taker_Name = "";
      this.care_Taker_Contact_Number = "";
    } else {
      this.location = savedPickUpLocatiomData.location;
      this.remark = savedPickUpLocatiomData.remark;
      this.pickup_Date = savedPickUpLocatiomData.pickup_Date;
      this.pickup_Time = savedPickUpLocatiomData.pickup_Time;
      this.care_Taker_Name = savedPickUpLocatiomData.care_Taker_Name;
      this.care_Taker_Contact_Number =
        savedPickUpLocatiomData.care_Taker_Contact_Number;
    }
  },
  methods: {
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapActions(["setDropOFFLocationDetails"]),

    moment: moment,

    isMobile() {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    previousView() {
      document
        .getElementById("pickUpLocationSectionDiv")
        .classList.remove("d-block");
      document
        .getElementById("pickUpLocationSectionDiv")
        .classList.add("d-none");
      // sessionStorage.setItem("CID", 1);
      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document
          .getElementById("dropOffLocationSection")
          .classList.add("d-block");
        document
          .getElementById("dropOffLocationSection")
          .classList.remove("d-none");
      }, 800);
    },
    showDropOffLocationMarker() {
      if (navigator.geolocation) {
        const lat = this.savedDropOffLocation.latitudes;
        const long = this.savedDropOffLocation.longitudes;

        const map = new google.maps.Map(document.getElementById("mapDiv"), {
          zoom: 14,
          center: new google.maps.LatLng(lat, long),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });

        const cityCircle = new google.maps.Circle({
          strokeColor: "#006203",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#006203",
          fillOpacity: 0.25,
          map,
          center: new google.maps.LatLng(lat, long),
          radius: this.savedDropOffLocation.operatingMaximumPortDistance,
        });
        new google.maps.Marker({
          position: new google.maps.LatLng(lat, long),
          map: map,
        });
      }
    },

    calculateDistance(lat, lang) {
      const dropOffLocationLatitudes =
        this.$store.getters.getDropOffLocationInputDetails.latitudes;
      const dropOffLocationLongitudes =
        this.$store.getters.getDropOffLocationInputDetails.longitudes;

      const calculatedDistance = haversine(
        { lat: dropOffLocationLatitudes, lng: dropOffLocationLongitudes },
        { lat: lat, lng: lang }
      );

      document.getElementById("distance").innerHTML = this.isLanguageEN
        ? "Distance between Drop Off and Pick up Location : " +
          (calculatedDistance / 1000).toFixed(0) +
          " KM"
        : "المسافة بين الإنزال والتقاط الموقع:" +
          (calculatedDistance / 1000).toFixed(0) +
          " " +
          "كم";

      setTimeout(() => {
        this.distanceVal = (calculatedDistance / 1000).toFixed(0);
      }, 1200);
    },

    setCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const map = new google.maps.Map(document.getElementById("mapDiv"), {
            zoom: 15,
            center: new google.maps.LatLng(latitude, longitude),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
          });

          this.showUserLocationOnTheMap(latitude, longitude);
          this.calculateDistance(latitude, longitude);
        },
        (error) => {}
      );
    },

    markLocation() {
      if (this.latitudes !== "" && this.longitudes !== "") {
        this.calculateDistance(this.latitudes, this.longitudes);
        if (navigator.geolocation) {
          const pickUplocationFunctions = this;
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // const { latitudes, longitudes } = this.dropOffLocationData[0];
              let map = new google.maps.Map(document.getElementById("mapDiv"), {
                zoom: 14,
                center: new google.maps.LatLng(this.latitudes, this.longitudes),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
              });
              google.maps.event.addListener(map, "click", function (event) {
                pickUplocationFunctions.selectLocation = true;
                pickUplocationFunctions.getAddressFormatted(
                  event.latLng.lat(),
                  event.latLng.lng()
                );
              });
            },
            (error) => {}
          );
        }
      }
    },

    setPicUpTime() {
      const dropOffLocationDetils = this.getDropOffLocationInputDetails;

      this.ValidDropOffDate = dropOffLocationDetils.dropOffDate;

      setTimeout(() => {
        var momentObj = moment(dropOffLocationDetils.dropOffDate, [
          "YYYY-MM-DD HH:mm",
        ]).subtract(dropOffLocationDetils.pickupLeadTime, "hours");

        var formattedDate = momentObj.format("HH:mm");

        this.pickup_Date = moment(momentObj).format("YYYY-MM-DD HH:mm");
        this.ValidPickupDateTime = moment(momentObj).format("YYYY-MM-DD HH:mm");

        this.pickup_Time = formattedDate;

        this.minPickUpTime = dropOffLocationDetils.validDropOffTime;
        this.maxPickUpDate = dropOffLocationDetils.pickupLeadTime;
      }, 100);
    },

    setValidateForPickupDateBlurFunc() {
      // const datepicker = document.getElementById('picUpDate');
      // datepicker.setAttribute('value', new Date().toISOString().split('T')[0]);
      document.getElementById("picUpDate").value = new Date()
        .toISOString()
        .split("T")[0];
    },

    checkCarTakerTelephone(event) {
      this.errors.care_Taker_Contact_Number = "";

      if (event.target.value === "") {
        this.errors.care_Taker_Contact_Number = "";
      } else if (isNaN(event.target.value)) {
        this.errors.care_Taker_Contact_Number = this.isLanguageEN
          ? "Input a valid mobile number"
          : "أدخل رقم هاتف محمول صالحًا";
      } else {
        if (!/^\+[1-9]{1}[0-9]{3,14}$/.test(event.target.value)) {
          this.errors.care_Taker_Contact_Number = this.isLanguageEN
            ? "Input contact number with country code eg. +966"
            : "أدخل رقم الاتصال مع رمز البلد ، مثل +966";
        } else {
          this.errors.care_Taker_Contact_Number = "";
        }
      }
    },

    setValidateForPickupDate() {
      var getlocation =
        this.$store.getters.getDropOffLocationInputDetails.dropOffDate;

      this.DropOffDate = getlocation;
      var systemPickupDate = moment(this.ValidPickupDateTime).format(
        "YYYYMMDDHHmm"
      );
      var maxPickUpDate = moment(getlocation).format("YYYYMMDDHHmm");

      var currentPickupDate = moment(this.pickup_Date).format("YYYYMMDDHHmm");

      if (
        parseInt(maxPickUpDate) >= parseInt(currentPickupDate) &&
        parseInt(currentPickupDate) >= parseInt(systemPickupDate)
      ) {
        this.TimeValid = true;
      } else {
        Swal.fire({
          title: this.isLanguageEN
            ? "<strong> Please choose a valid date and time </strong>"
            : "<strong> " + "الرجاء اختيار تاريخ ووقت صالحين" + " </strong>",
          html: this.isLanguageEN
            ? "In order to provide a better service, we only can operate  <br/> <b>" +
              `${moment(this.ValidPickupDateTime).format("YYYY-MM-DD HH:mm")}` +
              "</b> to <b>" +
              `${moment(this.ValidDropOffDate).format("YYYY-MM-DD HH:mm")}` +
              "</b>  <br/> based on your departure date. If you wish to know how we calculate time. Please check our <a href='#'> FAQ.</a> section. <br/>Thank you."
            : "من أجل تقديم خدمة أفضل ، يمكننا العمل فقط" +
              "  <br/> <b>" +
              `${moment(this.ValidPickupDateTime).format("YYYY-MM-DD HH:mm")}` +
              "</b> " +
              "إلى" +
              " <b>" +
              `${moment(this.ValidDropOffDate).format("YYYY-MM-DD HH:mm")}` +
              "</b>  <br/> " +
              "بناءً على تاريخ مغادرتك. إذا كنت ترغب في معرفة كيف نحسب الوقت. يرجى مراجعة" +
              " <a href='#'> FAQ.</a> " +
              "الجزء." +
              " <br/>" +
              "شكرًا لك.",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.isLanguageEN
            ? '<i class="fa fa-thumbs-up"></i> Reset The Time!'
            : '<i class="fa fa-thumbs-up"></i> ' + "إعادة ضبط الوقت!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              this.isLanguageEN
                ? "Time has been reset."
                : "تمت إعادة ضبط الوقت",
              ".",
              "",
              "success"
            );
            this.pickup_Date = this.ValidPickupDateTime;
            this.TimeValid = true;
          }
        });

        // VueSweetalert2.fire({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   // title:
        //   //   "Please choose a valid date time.The maximum date time is " +
        //   //   moment(getlocation).format("YYYY-MM-DD HH:mm") +
        //   //   ", and the minimum date time is " +
        //   //   moment(this.ValidPickupDateTime).format("YYYY-MM-DD HH:mm"),

        //   title: 'Please choose a valid date and time',
        //   icon: 'error',
        // });
        this.TimeValid = false;
      }
    },

    formatCareTakerName() {
      if (this.care_Taker_Name) {
        this.care_Taker_Name = this.care_Taker_Name.toString();
        this.care_Taker_Name =
          this.care_Taker_Name.charAt(0).toUpperCase() +
          this.care_Taker_Name.slice(1);
      }
    },

    formatLandmark() {
      this.errors.remark = "";
      if (this.remark) {
        this.remark = this.remark.toString();
        this.remark =
          this.remark.charAt(0).toUpperCase() + this.remark.slice(1);
      }
    },

    addPickUpLocation() {
      const location = document.getElementById("location");
      const remark = document.getElementById("remark");
      const picUpDate = document.getElementById("picUpDate");
      // const pickUptime = document.getElementById("pickUptime");
      const takerName = document.getElementById("takerName");
      const takerPhone = document.getElementById("takerPhone");

      if (
        this.distanceVal >
        this.savedDropOffLocation.operatingMaximumPortDistance
      ) {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          title: this.isLanguageEN
            ? "Maximum operating distance is " +
              this.savedDropOffLocation.operatingMaximumPortDistance +
              " km."
            : "مسافة التشغيل القصوى" +
              " " +
              this.savedDropOffLocation.operatingMaximumPortDistance +
              " " +
              "كم.",
          icon: "error",
        });

        // document.getElementById("distance").innerHTML =
        //   this.savedDropOffLocation.operatingMaximumPortDistance + " km";

        return;
      }

      let picUpLocations = {
        location: this.location,
        remark: this.remark,
        pickup_Date: this.pickup_Date,
        pickup_Time: this.pickup_Time,
        care_Taker_Name: this.care_Taker_Name,
        care_Taker_Contact_Number: this.care_Taker_Contact_Number,
        latitudes: this.latitudes,
        longitudes: this.longitudes,
        isLanguageEN: this.isLanguageEN,
        distanceVal: this.distanceVal,
      };

      if (
        this.care_Taker_Name === undefined &&
        this.care_Taker_Contact_Number === undefined
      ) {
        this.care_Taker_Name = "";
        this.care_Taker_Contact_Number = "";
        picUpLocations.care_Taker_Name = "";
        picUpLocations.care_Taker_Contact_Number = "";
      }

      if (this.care_Taker_Name) {
        if (
          this.care_Taker_Contact_Number === "" ||
          this.care_Taker_Contact_Number === undefined
        ) {
          this.errors.care_Taker_Contact_Number = this.isLanguageEN
            ? "Please enter care taker contact number"
            : "الرجاء إدخال رقم اتصال مقدم الرعاية";
          return;
        }
      } else if (this.care_Taker_Contact_Number) {
        if (this.care_Taker_Name === "" || this.care_Taker_Name === undefined) {
          this.errors.carTakerName = this.isLanguageEN
            ? "Please enter care taker name"
            : "الرجاء إدخال اسم مقدم الرعاية";
          return;
        }
        this.errors.care_Taker_Contact_Number = "";
      }

      const { isInvalid, errors } =
        validatePickUplocationDetailsInput(picUpLocations);

      if (isInvalid) {
        this.errors = errors;
        this.errors.location ? location.focus() : "";
        this.errors.remark ? remark.focus() : "";
        this.errors.pickUpdate ? picUpDate.focus() : "";
        this.errors.pickUpTime ? pickUptime.focus() : "";
        this.errors.carTakerName ? takerName.focus() : "";
        this.errors.care_Taker_Contact_Number ? takerPhone.focus() : "";
        return;
      } else {
        this.errors = {};
        if (this.TimeValid && this.isLocationSelected) {
          this.$store.dispatch("setPickupLocations", picUpLocations);

          // const BRDList = JSON.parse(sessionStorage.getItem("BRDL"));

          // if (BRDList != null) {
          //   var picUpLocationsList = BRDList.map((item) => ({
          //     ...item,
          //     picUpLocations: picUpLocations,
          //   }));
          //   // sessionStorage.setItem("CID", 2);
          //   // sessionStorage.setItem("BRDL", JSON.stringify(picUpLocationsList));
          // }

          document
            .getElementById("pickUpLocationSectionDiv")
            .classList.add("d-none");
          document
            .getElementById("pickUpLocationSectionDiv")
            .classList.remove("d-block");
          this.springLoader = true;
          setTimeout(() => {
            this.springLoader = false;
            document
              .getElementById("luggageDetailsSection")
              .classList.remove("d-none");
            document
              .getElementById("luggageDetailsSection")
              .classList.add("d-block");
          }, 800);

          window.location.href = "#luggageDetailsSec";
        } else {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: !this.TimeValid
              ? this.isLanguageEN
                ? "Please choose a valid date and time."
                : "الرجاء اختيار تاريخ ووقت صالحين."
              : this.isLanguageEN
              ? "Please choose a location."
              : "يرجى اختيار موقع.",
            icon: "error",
          });
        }
      }
    },

    locatorButtonPressed() {
      const originAutocomplete = new google.maps.places.Autocomplete(
        this.$refs["location"],
        {
          bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(23.6393629, 68.1471264)
          ),
        }
      );

      originAutocomplete.addListener("place_changed", () => {
        const place = originAutocomplete.getPlace();
        this.location = place.formatted_address;
        this.latitudes = place.geometry.location.lat();
        this.longitudes = place.geometry.location.lng();
        this.showUserLocationOnTheMap(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        this.calculateDistance(this.latitudes, this.longitudes);
      });
    },

    getAddressFormatted(lat, long) {
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBAONHmCYbfJ9-yFwfNPrKkaMUNJzz0huc"
        )
        .then((response) => {
          if (response.data.error_message) {
            this.error = response.data.error_message;
          } else {
            this.address = response.data.results[0].formatted_address;

            if (this.selectLocation) {
              this.showUserLocationOnTheMap(lat, long);
              this.calculateDistance(lat, long);
            }

            this.location = response.data.results[0].formatted_address;
            this.calculateDistance(lat, long);
          }
        })
        .catch((error) => {
          this.error = error.message;
        });
    },

    showUserLocationOnTheMap(lat, long) {
      this.isLocationSelected = true;
      const dropOffLocationLatitudes =
        this.$store.getters.getDropOffLocationInputDetails.latitudes;
      const dropOffLocationLongitudes =
        this.$store.getters.getDropOffLocationInputDetails.longitudes;

      const calculatedDistance = haversine(
        { lat: dropOffLocationLatitudes, lng: dropOffLocationLongitudes },
        { lat: lat, lng: long }
      );

      var distance = (calculatedDistance / 1000).toFixed(0);

      const map = new google.maps.Map(document.getElementById("mapDiv"), {
        zoom: distance > 50 ? 7 : 10,
        center: new google.maps.LatLng(lat, long),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      const dropOffLocationDetails = this.getDropOffLocationInputDetails;

      const locations = [
        {
          latitude: dropOffLocationDetails.latitudes,
          longitude: dropOffLocationDetails.longitudes,
          title: "Drop Off Location",
        },

        {
          latitude: lat,
          longitude: long,
          title: "Pickup Location",
        },
      ];

      let cityCircle = new google.maps.Circle({
        strokeColor: "#006203",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#006203",
        fillOpacity: 0.25,
        map,
        center: new google.maps.LatLng(
          dropOffLocationDetails.latitudes,
          dropOffLocationDetails.longitudes
        ),
        radius: dropOffLocationDetails.operatingMaximumPortDistance * 1000,
      });

      let marker = [2];

      for (let i = 0; i < locations.length; i++) {
        if (i == 0) {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              locations[i].latitude,
              locations[i].longitude
            ),
            map: map,
            title: locations[i].title,
            draggable: false,
          });
        } else {
          marker[i] = new google.maps.Marker({
            map: map,
            title: locations[i].title,
            draggable: true,
            position: new google.maps.LatLng(
              locations[i].latitude,
              locations[i].longitude
            ),
            icon: {
              url: pickupLocationIcon,
              scaledSize: new google.maps.Size(40, 40),
            },
          });

          marker[1].addListener("dragend", (event) => {
            this.latitudes = event.latLng.lat();
            this.longitudes = event.latLng.lng();

            this.showUserLocationOnTheMap(this.latitudes, this.longitudes);
            this.getAddressFormatted(this.latitudes, this.longitudes);
            this.calculateDistance(this.latitudes, this.longitudes);

            setTimeout(() => {
              if (
                this.distanceVal >
                dropOffLocationDetails.operatingMaximumPortDistance
              ) {
                const cityCircle = new google.maps.Circle({
                  strokeColor: "#EA4335",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#EA4335",
                  fillOpacity: 0.25,
                  map,
                  center: new google.maps.LatLng(
                    dropOffLocationDetails.latitudes,
                    dropOffLocationDetails.longitudes
                  ),
                  radius:
                    dropOffLocationDetails.operatingMaximumPortDistance * 1000,
                });
                document
                  .getElementById("distance")
                  .classList.add("text-danger");
              } else {
                document
                  .getElementById("distance")
                  .classList.remove("text-danger");
                const cityCircle = new google.maps.Circle({
                  strokeColor: "#006203",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#006203",
                  fillOpacity: 0.25,
                  map,
                  center: new google.maps.LatLng(
                    dropOffLocationDetails.latitudes,
                    dropOffLocationDetails.longitudes
                  ),
                  radius:
                    dropOffLocationDetails.operatingMaximumPortDistance * 1000,
                });
              }
            }, 1700);
          });
        }
      }

      setTimeout(() => {
        if (
          this.distanceVal > dropOffLocationDetails.operatingMaximumPortDistance
        ) {
          const cityCircle = new google.maps.Circle({
            strokeColor: "#EA4335",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#EA4335",
            fillOpacity: 0.25,
            map,
            center: new google.maps.LatLng(
              dropOffLocationDetails.latitudes,
              dropOffLocationDetails.longitudes
            ),
            radius: dropOffLocationDetails.operatingMaximumPortDistance * 1000,
          });
          document.getElementById("distance").classList.add("text-danger");
        } else {
          document.getElementById("distance").classList.remove("text-danger");
          const cityCircle = new google.maps.Circle({
            strokeColor: "#006203",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#006203",
            fillOpacity: 0.25,
            map,
            center: new google.maps.LatLng(
              dropOffLocationDetails.latitudes,
              dropOffLocationDetails.longitudes
            ),
            radius: dropOffLocationDetails.operatingMaximumPortDistance * 1000,
          });
        }
      }, 1700);
    },

    getDataFromLocalStorageShowUserLocationOnTheMap(lat, long) {
      // this.isLocationSelected = true;

      const dropOffLocationLatitudes = this.dropLocationlatitudes;
      const dropOffLocationLongitudes = this.dropLocationlongitudes;

      const calculatedDistance = haversine(
        { lat: dropOffLocationLatitudes, lng: dropOffLocationLongitudes },
        { lat: lat, lng: long }
      );

      var distance = (calculatedDistance / 1000).toFixed(0);

      const map = new google.maps.Map(document.getElementById("mapDiv"), {
        zoom: distance > 50 ? 7 : 10,
        center: new google.maps.LatLng(lat, long),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      // const dropOffLocationDetails = this.getDropOffLocationInputDetails;

      const locations = [
        {
          latitude: dropOffLocationLatitudes,
          longitude: dropOffLocationLongitudes,
          title: "Drop Off Location",
        },

        {
          latitude: lat,
          longitude: long,
          title: "Pickup Location",
        },
      ];

      let cityCircle = new google.maps.Circle({
        strokeColor: "#006203",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#006203",
        fillOpacity: 0.25,
        map,
        center: new google.maps.LatLng(
          dropOffLocationLatitudes,
          dropOffLocationLongitudes
        ),
        radius: this.dropOffLocationOperatingMaximumPortDistance * 1000,
      });

      let marker = [2];

      for (let i = 0; i < locations.length; i++) {
        if (i == 0) {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              locations[i].latitude,
              locations[i].longitude
            ),
            map: map,
            title: locations[i].title,
            draggable: false,
          });
        } else {
          marker[i] = new google.maps.Marker({
            map: map,
            title: locations[i].title,
            draggable: true,
            position: new google.maps.LatLng(
              locations[i].latitude,
              locations[i].longitude
            ),
            icon: {
              url: pickupLocationIcon,
              scaledSize: new google.maps.Size(40, 40),
            },
          });
          console.log("Step 03", marker);

          marker[1].addListener("dragend", (event) => {
            // this.latitudes = event.latLng.lat();
            // this.longitudes = event.latLng.lng();

            // this.getDataFromLocalStorageShowUserLocationOnTheMap(
            //   this.latitudes,
            //   this.longitudes
            // );
            // this.getAddressFormatted(this.latitudes, this.longitudes);
            // this.calculateDistance(this.latitudes, this.longitudes);

            setTimeout(() => {
              document
                .getElementById("distance")
                .classList.remove("text-danger");
              const cityCircle = new google.maps.Circle({
                strokeColor: "#006203",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#006203",
                fillOpacity: 0.25,
                map,
                center: new google.maps.LatLng(
                  dropOffLocationLatitudes,
                  dropOffLocationLongitudes
                ),
                radius: this.dropOffLocationOperatingMaximumPortDistance * 1000,
              });
            }, 1700);
          });
        }
      }

      setTimeout(() => {
        if (this.distanceVal > dropOffLocationOperatingMaximumPortDistance) {
          const cityCircle = new google.maps.Circle({
            strokeColor: "#EA4335",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#EA4335",
            fillOpacity: 0.25,
            map,
            center: new google.maps.LatLng(
              dropOffLocationDetails.latitudes,
              dropOffLocationDetails.longitudes
            ),
            radius: dropOffLocationDetails.operatingMaximumPortDistance * 1000,
          });
          document.getElementById("distance").classList.add("text-danger");
        } else {
          document.getElementById("distance").classList.remove("text-danger");
          const cityCircle = new google.maps.Circle({
            strokeColor: "#006203",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#006203",
            fillOpacity: 0.25,
            map,
            center: new google.maps.LatLng(
              dropOffLocationDetails.latitudes,
              dropOffLocationDetails.longitudes
            ),
            radius: dropOffLocationDetails.operatingMaximumPortDistance * 1000,
          });
        }
      }, 1700);
    },
    setValidatePickupTime() {
      var pickUpOffTime = document.getElementById("pickUptime").value;

      var CurrentDropTime = pickUpOffTime.replace(":", ".");
      var CurrentDropDate = moment(this.dropOffDate).format("YYYYMMDD");

      // var dropOffTimeSubtractFromPortLeadTime = moment(this.depTime, [
      //   "HH:mm",
      // ]).subtract(this.portLeadTime, "hours");

      // var formattedTime = dropOffTimeSubtractFromPortLeadTime.format("HH:mm");
      // this.minValidDropTime = formattedTime;

      // if (
      //   parseFloat(dropOffTime.replace(":", ".")) <=
      //   parseFloat(this.minValidDropTime.replace(":", "."))
      // ) {
      //   this.isTimeValid01 = true;
      // } else {
      //   VueSweetalert2.fire({
      //     toast: true,
      //     position: "top-end",
      //     showConfirmButton: false,
      //     timer: 3000,
      //     title: "Please choose a valid time step 02",
      //     icon: "error",
      //   });

      //   this.isTimeValid01 = false;
      // }

      if (
        parseFloat(pickUpOffTime.replace(":", ".")) >=
        parseFloat(this.minPickUpTime.replace(":", "."))
      ) {
        this.isTimeValid02 = true;
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "Please choose a valid time step 01.",
          icon: "error",
        });
        this.isTimeValid02 = false;
      }
    },
  },
};
</script>

<style scoped>
#loading {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 25%; */
}
.btn-previous {
  background: #ffffff;
  color: #595f69;
  width: 110px;
  height: 38px;
  border: 1px solid #e8e9eb;
  border-radius: 13px;
  box-shadow: none !important;
}

.btn-next {
  background: #000080;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 13px;
  box-shadow: none !important;
}
.btn-next:hover {
  color: #fff;
  background: #000080;
}
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.container-fluid {
  padding: 0px 140px 20px 140px;
}

#takerName {
  height: 41px;
}

.btn-Icon-location {
  border-radius: 10px;
  background: #000080;
  color: #ffffff;
  text-align: center;
}

/* .btn-Icon-location i {
  color: #ffffff;
} */

.mapDiv {
  border-radius: 15px;
  height: 300px;
  border: 1px solid #e8e9eb;
  margin-top: 15px;
  margin-bottom: 20px;
}

.searchLocation {
  position: relative;
  bottom: 32px;
  left: 100px;
}

input:focus {
  outline: none;
  border-color: #000080;
  box-shadow: none;
}

input[type="text"] {
  border-radius: 13px;
}

input[type="email"] {
  border-radius: 13px;
}
.label-front {
  font-size: 13px;
}
@media (min-width: 1024px) and (max-width: 1260px) {
  .container-fluid {
    padding: 0px 180px 20px 100px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container-fluid {
    padding: 0px 70px 20px 70px;
  }
}

@media (min-width: 599px) and (max-width: 768px) {
  .container-fluid {
    padding: 0px 60px 20px 30px;
  }
}
@media (min-width: 425px) and (max-width: 599px) {
  .container-fluid {
    padding: 0px 100px 20px 0px;
  }
}

@media (min-width: 200px) and (max-width: 425px) {
  .container-fluid {
    padding: 0px 0px 20px 0px;
  }
}
</style>
