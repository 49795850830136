<template>
  <div id="loading" v-show="springLoader">
    <DoubleBounce></DoubleBounce>
  </div>
  <div id="dropOffLocationSection" class="d-block">
    <div
      id="dropOffSec"
      class="row d-flex justify-content-center align-item-center mt-2 mb-3"
    >
      <SectionHeader :header-title="dropOffLocationLabel" />
    </div>

    <div class="container-fluid">
      <div
        class="row d-flex justify-content-center align-items-center"
        v-if="notice"
      >
        <div class="notification-card p-0">
          <div class="info__padding">
            <h3 class="d-flex justify-content-center text-danger">
              !! Important Notice !!
            </h3>
            <p>
              {{ selectedCurrency }} is your current currency. Please
              double-check your currency before filling out the form. If you
              change the currency while filling out the form, your data will be
              reset.
            </p>
          </div>
          <div class="button__group">
            <!-- <button @click="setNoticeStatus()">Close</button> -->
            <button @click="setNoticeStatus()">Agreed</button>
          </div>
        </div>
      </div>

      <form id="dropOffLocationFrom" :dir="!isLanguageEN ? 'rtl' : ''">
        <div class="row d-flex justify-content-center align-item-center mx-0">
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50" for="dropOffLocation">{{
                dropOffLocationLabel.fightNumber
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="flightNumber"
                v-model="flightNumber"
                aria-describedby="flightNumber"
                :class="`form-control border-1 ${
                  errors.flightNumber ? 'is-invalid' : ''
                }`"
                @keyup="errors.flightNumber = ''"
                type="text"
              />
              <small
                id="flightNumberError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.flightNumber }}</small
              >
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg-0">
            <div class="form-group">
              <label class="opacity-50" for="dropOffLocation">{{
                dropOffLocationLabel.location
              }}</label
              ><label class="text-danger">*</label>
              <select
                id="dropOffLocation"
                aria-describedby="dropOffLocationError"
                :class="`form-select ${errors.location ? 'is-invalid' : ''}`"
                @change="setLocation($event)"
              >
                <option
                  id="dropOffLocationID"
                  v-for="(dropOffLocations, index) of dropOffLocationData"
                  :value="dropOffLocations.portId"
                  :key="index"
                >
                  {{ dropOffLocations.portName }}
                </option>
              </select>
              <small
                id="dropOffLocationError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.location }}</small
              >
            </div>
          </div>

          <div class="col-lg-6 col-sm-12 me-md-0">
            <div class="form-group">
              <label class="opacity-50" for="depTime">{{
                dropOffLocationLabel.dep_DateAndTime
              }}</label
              ><label class="text-danger">*</label>

              <VueCtkDateTimePicker
                id="drop_"
                noLabel="true"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                color="#000080"
                button-color="#000080"
                v-model="depDateTime"
                :auto-close="false"
                :min-date="new Date().toISOString().split('T')[0]"
                @validate="setDepTimeOfDropOffLocation(depDateTime)"
                style="border-radius: 13px"
                noButtonNow="disabled"
              />

              <small
                id="dropOffTimeError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.dropOffTime }}</small
              >
              <label class="label-front">
                <i
                  ><i class="fas fa-info-circle"></i>
                  {{ dropOffLocationLabel.minimumDateTime }}
                  {{ moment(this.ValidDepDateTime).format("YYYY-MM-DD HH:mm") }}
                </i>
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 me-md-0">
            <div class="form-group">
              <label class="opacity-50" for="depTime">{{
                dropOffLocationLabel.drop_off_DateAndTime
              }}</label
              ><label class="text-danger">*</label>

              <VueCtkDateTimePicker
                noLabel="true"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                color="#000080"
                button-color="#000080"
                v-model="DropDateTime"
                :min-date="moment().format('YYYY-MM-DD')"
                :max-date="moment(this.depDateTime).format('YYYY-MM-DD')"
                @validate="setValidateDropTime()"
                style="border-radius: 13px"
                noButtonNow="disabled"
              />

              <small
                id="dropOffTimeError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.dropOffTime }}</small
              >
              <label class="label-front">
                <i
                  ><i class="fas fa-info-circle"></i>
                  {{ dropOffLocationLabel.minimumDateTime }}
                  {{
                    moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm")
                  }}
                </i>
                &nbsp;
                <i v-show="this.isMaxLabel"
                  ><i class="fas fa-info-circle ml-2 mr-2"></i>
                  {{ dropOffLocationLabel.maxDateTime }}
                  {{
                    moment(this.ValidMaxDropDateTime).format("YYYY-MM-DD HH:mm")
                  }}
                </i>
              </label>
            </div>
          </div>

          <!-- 
          <div class="col-lg-6 col-sm-12 mb-2 mb-lg">
            <div class="form-group">
              <label class="opacity-50 dropOffSetTime" for="dropOffDate">{{
                dropOffLocationLabel.Dep_Date
              }}</label
              ><label class="text-danger">*</label>

              <VueCtkDateTimePicker
                only-date="true"
                noLabel="true"
                format="YYYY-MM-DD"
                formatted="YYYY-MM-DD"
                color="#006203"
                v-model="depDate"
                :min-date="new Date().toISOString().split('T')[0]"
                @click="errors.dropOffdate = ''"
                @validate="setValidateDepTime()"
                style="border-radius: 13px"
              />
              <small
                id="dropOffDateError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.dropOffdate }}</small
              >
            </div>
          </div>
          // <div class="col-lg-6 col-sm-12 me-md-0">
          //   <div class="form-group">
          //     <label class="opacity-50" for="depTime">{{
          //       dropOffLocationLabel.Dep_Time
          //     }}</label
          //     ><label class="text-danger">*</label>

          //     <VueCtkDateTimePicker
          //       only-time="true"
          //       noLabel="true"
          //       format="HH:mm"
          //       formatted="HH:mm"
          //       color="#006203"
          //       button-color="#006203"
          //       v-model="depTime"
          //       @validate="setDepTimeOfDropOffLocation(depTime)"
          //       style="border-radius: 13px"
          //     />

          //     <small
          //       id="dropOffTimeError"
          //       class="d-block text-danger form-text invalid-feedback"
          //       >{{ errors.dropOffTime }}</small
          //     >
          //   </div>
          // </div>
          // <div class="col-lg-6 col-sm-12 mb-2 mb-lg">
          //   <div class="form-group">
          //     <label class="opacity-50" for="dropOffDate">{{
          //       dropOffLocationLabel.drop_off_Date
          //     }}</label
          //     ><label class="text-danger">*</label>

          //     <VueCtkDateTimePicker
          //       id="dropOffDate"
          //       only-date="true"
          //       noLabel="true"
          //       format="YYYY-MM-DD"
          //       formatted="YYYY-MM-DD"
          //       color="#006203"
          //       v-model="dropOffDate"
          //       :min-date="moment().format('YYYY-MM-DD')"
          //       :max-date="moment().add(2, 'days').format('YYYY-MM-DD')"
          //       @click="errors.dropOffdate = ''"
          //       @validate="setValidateDropTime()"
          //       style="border-radius: 13px"
          //     />
          //     <small
          //       id="dropOffDateError"
          //       class="d-block text-danger form-text invalid-feedback"
          //       >{{ errors.dropOffdate }}</small
          //     >
          //   </div>
          // </div>
          // <div class="col-lg-6 col-sm-12" id="secFeild">
          //   <div class="form-group">
          //     <label class="opacity-50" for="dropOffTime">{{
          //       dropOffLocationLabel.drop_off_Time
          //     }}</label
          //     ><label class="text-danger">*</label>
          //     <VueCtkDateTimePicker
          //       only-time="true"
          //       noLabel="true"
          //       format="HH:mm"
          //       formatted="HH:mm"
          //       color="#006203"
          //       v-model="dropOffTime"
          //       @click="errors.dropOffTime = ''"
          //       @validate="setValidateDropTime()"
          //       style="border-radius: 13px"
          //     />

          //     <small
          //       id="dropOffTimeError"
          //       class="d-block text-danger form-text invalid-feedback"
          //       >{{ errors.dropOffTime }}</small
          //     >
          //   </div>
          // </div>

          !-->

          <div class="col-md-12 mx-0">
            <div class="form-group">
              <div id="dropOffMapDiv" class="mapDiv" ref="mapDiv"></div>
            </div>
          </div>

          <!-- <ButtonList
            :save="getDropLocation"
            :next="dropOffLocationLabel.nextStep"
            :previous="dropOffLocationLabel.previous"
            :buttonName="buttonName"
          /> -->
          <div class="row mt-4 pb-lg-4 mx-0">
            <div
              class="
                col-6
                row
                flex-row
                d-flex
                justify-content-lg-end
                justify-content-md-end
                justify-content-sm-center
                ps-0
                mx-0
              "
            >
              <div
                class="
                  form-group
                  w-100
                  row
                  flex-row
                  d-flex
                  justify-content-lg-end
                  justify-content-md-end
                  justify-content-sm-center
                  mx-0
                "
              >
                <button type="button" class="btn btn-previous">Previous</button>
              </div>
            </div>
            <div
              class="
                col-6
                row
                flex-row
                d-flex
                justify-content-lg-start
                justify-content-md-start
                justify-content-sm-center
                ps-0 ps-md-3 ps-lg-3
                mx-0
              "
            >
              <div
                class="
                  form-group
                  w-100
                  row
                  flex-row
                  d-flex
                  justify-content-lg-start
                  justify-content-md-start
                  justify-content-sm-center
                  mx-0
                "
              >
                <button
                  type="button"
                  class="btn btn-next"
                  @click="setDropOffLocation"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SectionHeader from "../../../common/components/booking/SectionHeader";
import ButtonList from "../../../common/components/booking/ButtonList";
import Map from "../../../common/components/booking/Map";
import validateDropOffLocationDetailsInput from "../../../validations/DropOffLocation/DropOffLocationValidation.js";
import VueSweetalert2 from "sweetalert2";
import DropOffLocationService from "../../../services/DropOffLocationService";
import vuex from "vuex";
import "jquery-clock-timepicker";
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { DoubleBounce } from "vue-loading-spinner";

export default {
  name: "DropOffLocation",
  components: {
    Map,
    ButtonList,
    SectionHeader,
    VueCtkDateTimePicker,
    DoubleBounce,
  },
  data: function () {
    return {
      dropOffLocationLabel: this.$store.getters.getDropOffLocationDetails,
      buttonName: "Next Step",
      location: "",
      dropOffDate: "",
      dropOffTime: "",
      depDate: "",
      depTime: "",
      dropOffLocationData: [],
      latitude: 0,
      longitude: 0,
      errors: {},
      portLeadTime: 0,
      operatingMaximumPortDistance: 0,
      pickupDuration: 0,
      pickupLeadTime: 0,
      flightNumber: "",
      ValidDepDate: "",
      ValidDepTime: "",
      ValidDropOffDate: "",
      ValidDropOffTime: "",
      minValidDropTime: "",
      isTimeValid01: true,
      isTimeValid02: true,
      depDateTime: "",
      DropDateTime: "",
      ValidDepDateTime: "",
      ValidDropDateTime: "",
      ValidMaxDropDateTime: "",
      isLanguageEN: true,
      isMaxLabel: false,
      springLoader: false,
      selectedCurrency: "SAR",
      currencyRate: 0,
      notice: false,
    };
  },
  computed: {
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
  },

  watch: {
    getPrimaryPassengerDetails: {
      handler: function (val) {},
      deep: true,
    },
  },

  mounted: function () {
    const noticeStatus = sessionStorage.getItem("UFNS");
    if (noticeStatus != null) {
      if (parseInt(noticeStatus) == 0) {
        this.notice = false;
      }

      if (parseInt(noticeStatus) === 1) {
        setTimeout(() => {
          this.notice = true;
        }, 2000);
      }
    } else {
      sessionStorage.setItem("UFNS", 1);
      setTimeout(() => {
        this.notice = true;
      }, 2000);
    }

    const currentCurrencyRate = JSON.parse(localStorage.getItem("CR"));

    if (currentCurrencyRate != null) {
      this.selectedCurrency = currentCurrencyRate.currency;
      this.currencyRate = currentCurrencyRate.currentRate;
    }
    const CRList = JSON.parse(sessionStorage.getItem("BRDL"));
    if (CRList != null) {
    } else {
      sessionStorage.setItem("BRDL", JSON.stringify([]));
    }

    $(".timePicker").clockTimePicker({
      duration: false,
      durationNegative: true,
      precision: 5,
      i18n: {
        cancelButton: "Canel",
      },
      onAdjust: function (newVal) {
        const func = this;

        func.dropOffTime = newVal;
      },
    });

    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }

    DropOffLocationService.getDropOffLocations()
      .then((res) => {
        this.dropOffLocationData = res.data.filter(
          (i) => i.status !== "Disabled"
        );

        //New code
        this.location = this.dropOffLocationData[0].portId;
        this.setSecondMethodLocation(this.dropOffLocationData[0]);

        const { latitudes, longitudes } = this.dropOffLocationData[0];
        this.pickupLeadTime = this.dropOffLocationData[0].pickupLeadTime;
        this.operatingMaximumPortDistance =
          this.dropOffLocationData[0].operatingMaximumPortDistance;
        this.pickupDuration = this.dropOffLocationData[0].pickupDuration;
        const map = new google.maps.Map(
          document.getElementById("dropOffMapDiv"),
          {
            zoom: 5,
            center: new google.maps.LatLng(latitudes, longitudes),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
          }
        );
        const cityCircle = new google.maps.Circle({
          strokeColor: "#006203",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#006203",
          fillOpacity: 0.35,
          map,
          center: new google.maps.LatLng(latitudes, longitudes),
          radius: this.operatingMaximumPortDistance * 1000,
        });

        new google.maps.Marker({
          position: new google.maps.LatLng(latitudes, longitudes),
          map: map,
        });

        this.setLocation(0);
      })
      .catch((err) => {});
  },
  methods: {
    ...vuex.mapActions(["setDropOFFLocationDetails"]),
    ...vuex.mapActions(["setConfirmUserDetails"]),

    moment: moment,

    setNoticeStatus() {
      this.notice = false;
      sessionStorage.setItem("UFNS", 0);
    },
    setDepTimeOfDropOffLocation(event) {
      this.errors.dropOffTime = "";
      this.isMaxLabel = true;

      setTimeout(() => {
        this.depTime = event;
        this.setValidateDepTime();
        var momentObj = moment(this.depDateTime, ["YYYY-MM-DD HH:mm"]).subtract(
          this.portLeadTime,
          "hours"
        );
        var formattedDate = momentObj.format("HH:mm");

        this.DropDateTime = moment(momentObj).format("YYYY-MM-DD HH:mm");
        this.ValidMaxDropDateTime =
          moment(momentObj).format("YYYY-MM-DD HH:mm");
      }, 100);
    },

    markLocation() {
      //mark location which is clicked
      if (navigator.geolocation) {
        const dropOffLocationFunctions = this;
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitudes, longitudes } = this.dropOffLocationData[0];

            let map = new google.maps.Map(
              document.getElementById("dropOffMapDiv"),
              {
                zoom: 5,
                center: new google.maps.LatLng(latitudes, longitudes),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
              }
            );

            google.maps.event.addListener(map, "click", function (event) {
              dropOffLocationFunctions.showUserLocationOnTheMap(
                event.latLng.lat(),
                event.latLng.lng()
              );
            });
          },
          (error) => {}
        );
      }
    },

    showUserLocationOnTheMap(lat, long) {
      const map = new google.maps.Map(
        document.getElementById("dropOffMapDiv"),
        {
          zoom: 5,
          center: new google.maps.LatLng(lat, long),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        }
      );

      new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        center: new google.maps.LatLng(lat, long),
        radius: this.operatingMaximumPortDistance * 1000,
      });

      new google.maps.Marker({
        position: new google.maps.LatLng(lat, long),
        map: map,
      });
    },

    getDropLocation() {
      const id = document.getElementById("dropOffLocation").value;

      // this.dropOffTime = document.getElementById("dropOffTime").value;

      const locationVal = document.getElementById("dropOffLocation");

      const locationSelectedval =
        locationVal.options[
          document.getElementById("dropOffLocation").selectedIndex
        ].text;

      const dropOffLocationLatitude = parseInt(
        document.getElementById("dropOffLocation").value
      );
      const dflData = this.dropOffLocationData.find(
        (item) => item.portId == dropOffLocationLatitude
      );

      const dropOffLatitudeSelected = dflData.latitudes;
      const dropOfflongitudesSelected = dflData.longitudes;

      let dropOffLocation = {
        dropOffLocationID: parseInt(id),
        dropOffLocationCode: "",
        location: locationSelectedval,
        dropOffDate: this.DropDateTime,
        dropOffTime: this.dropOffTime,
        depDate: this.depDateTime,
        depTime: this.depTime,
        operatingMaximumPortDistance: this.operatingMaximumPortDistance,
        pickupDuration: this.pickupDuration,
        pickupLeadTime: this.pickupLeadTime,
        flightNumber: this.flightNumber,
        validDropOffTime: this.ValidDropOffTime,
        latitudes: dropOffLatitudeSelected,
        longitudes: dropOfflongitudesSelected,
        isLanguageEN: this.isLanguageEN,
        portLeadTime: this.portLeadTime,
      };

      // const BRDList = JSON.parse(sessionStorage.getItem("BRDL"));

      // if (BRDList != null) {
      //   var obj = [
      //     {
      //       dropOffLocations: dropOffLocation,
      //     },
      //   ];

      //   // sessionStorage.setItem("CID", 1);
      //   // sessionStorage.setItem("BRDL", JSON.stringify(obj));
      // }

      // dropOffLocation.latitudes = dropOffLatitudeSelected;
      // dropOffLocation.longitudes = dropOfflongitudesSelected;

      const { isInvalid, errors } =
        validateDropOffLocationDetailsInput(dropOffLocation);

      if (isInvalid) {
        this.errors = errors;

        this.errors.flightNumber ? flightNumber.focus() : "";
      } else {
        this.errors = {};

        if (this.isTimeValid01 && this.isTimeValid02) {
          this.setDropOFFLocationDetails(dropOffLocation);
          document
            .getElementById("dropOffLocationSection")
            .classList.add("d-none");
          document
            .getElementById("dropOffLocationSection")
            .classList.remove("d-block");
          this.springLoader = true;
          setTimeout(() => {
            this.springLoader = false;
            document
              .getElementById("pickUpLocationSectionDiv")
              .classList.remove("d-none");
            document
              .getElementById("pickUpLocationSectionDiv")
              .classList.add("d-block");
          }, 800);
        } else {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: this.isLanguageEN
              ? "Please choose a valid date and time."
              : "الرجاء اختيار تاريخ ووقت صالحين.",
            icon: "error",
          });
        }
      }
    },

    setLocation(location) {
      this.errors.location = "";

      const locationVal = document.getElementById("dropOffLocation");

      const locationSelectedval =
        locationVal.options[
          document.getElementById("dropOffLocation").selectedIndex
        ].text;

      this.portLeadTime =
        this.dropOffLocationData[location.target.selectedIndex].portLeadTime;

      const latitude =
        this.dropOffLocationData[location.target.selectedIndex].latitudes;
      const longitudes =
        this.dropOffLocationData[location.target.selectedIndex].longitudes;

      this.operatingMaximumPortDistance =
        this.dropOffLocationData[
          location.target.selectedIndex
        ].operatingMaximumPortDistance;

      const portLeadTime =
        this.dropOffLocationData[location.target.selectedIndex].portLeadTime;

      this.pickupDuration =
        this.dropOffLocationData[location.target.selectedIndex].pickupDuration;

      this.pickupLeadTime =
        this.dropOffLocationData[location.target.selectedIndex].pickupLeadTime;

      const map = new google.maps.Map(
        document.getElementById("dropOffMapDiv"),
        {
          zoom: 5,
          center: new google.maps.LatLng(latitude, longitudes),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        }
      );

      const cityCircle = new google.maps.Circle({
        strokeColor: "#006203",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#006203",
        fillOpacity: 0.35,
        map,
        center: new google.maps.LatLng(latitude, longitudes),
        radius: this.operatingMaximumPortDistance * 1000,
      });

      new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitudes),
        map: map,
      });

      this.setSecondMethodLocation(
        this.dropOffLocationData[location.target.selectedIndex]
      );
    },

    //New code
    setSecondMethodLocation(location) {
      this.errors.location = "";

      const locationVal = document.getElementById("dropOffLocation");

      this.portLeadTime = location.portLeadTime;

      const latitude = location.latitudes;
      const longitudes = location.longitudes;

      this.operatingMaximumPortDistance = location.operatingMaximumPortDistance;

      const portLeadTime = location.portLeadTime;

      this.pickupDuration = location.pickupDuration;

      const map = new google.maps.Map(
        document.getElementById("dropOffMapDiv"),
        {
          zoom: 5,
          center: new google.maps.LatLng(latitude, longitudes),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        }
      );

      const cityCircle = new google.maps.Circle({
        strokeColor: "#006203",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#006203",
        fillOpacity: 0.35,
        map,
        center: new google.maps.LatLng(latitude, longitudes),
        radius: this.operatingMaximumPortDistance * 1000,
      });

      new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitudes),
        map: map,
      });

      var currentTime = moment().format("HH:mm");
      var momentObj = moment(currentTime.toString(), ["HH:mm"]).add(
        this.pickupDuration,
        "hours"
      );

      //adding port lead time to current time
      var momentObj2 = moment(momentObj).add(portLeadTime, "hours");

      var formattedDate = momentObj2.format("HH:mm");
      var currentDateInPoints = moment().format("HH.mm");
      var dropOffTimeInPoints =
        parseFloat(this.pickupDuration) +
        parseFloat(portLeadTime) +
        parseFloat(currentDateInPoints);

      if (dropOffTimeInPoints > 24) {
        var date = moment().add(1, "days").format("YYYY-MM-DD");
        this.depDateTime = moment(date + " " + formattedDate).format(
          "YYYY-MM-DD HH:mm"
        );
        this.ValidDepDate = moment().add(1, "days").format("YYYY-MM-DD");
        this.ValidDepDateTime = moment(date + " " + formattedDate).format(
          "YYYY-MM-DD HH:mm"
        );
      } else {
        var date = momentObj.format("YYYY-MM-DD");
        this.depDateTime = moment(date + " " + formattedDate).format(
          "YYYY-MM-DD HH:mm"
        );
        this.ValidDepDateTime = moment(date + " " + formattedDate).format(
          "YYYY-MM-DD HH:mm"
        );
        this.ValidDepDate = momentObj.format("YYYY-MM-DD");
      }

      this.setSecondDepTimeOfDropOffLocation(formattedDate);
    },

    //New Code
    setSecondDepTimeOfDropOffLocation(data) {
      this.errors.dropOffTime = "";
      setTimeout(() => {
        this.depTime = data;

        this.ValidDepTime = data;
        var momentObj = moment(this.depDateTime, ["YYYY-MM-DD HH:mm"]).subtract(
          this.portLeadTime,
          "hours"
        );
        var formattedDate = momentObj.format("HH:mm");

        this.DropDateTime = moment(momentObj).format("YYYY-MM-DD HH:mm");
        this.ValidMaxDropDateTime =
          moment(momentObj).format("YYYY-MM-DD HH:mm");
        this.ValidDropDateTime = moment(momentObj).format("YYYY-MM-DD HH:mm");

        var formattedDate = momentObj.format("HH:mm");

        var dropOffTimeInPoints =
          parseFloat(this.portLeadTime) + parseFloat(formattedDate);

        if (dropOffTimeInPoints >= 24) {
          this.dropOffDate = moment(momentObj)
            .add(1, "days")
            .format("YYYY-MM-DD");
          this.ValidDropOffDate = moment(momentObj)
            .add(1, "days")
            .format("YYYY-MM-DD");
        } else {
          this.dropOffDate = momentObj.format("YYYY-MM-DD");
          this.ValidDropOffDate = momentObj.format("YYYY-MM-DD");
        }

        // this.dropOffDate = momentObj.format("YYYY-MM-DD");
        this.dropOffTime = formattedDate;

        // this.ValidDropOffDate = momentObj.format("YYYY-MM-DD");
        this.ValidDropOffTime = formattedDate;
      }, 100);
    },
    setDropOffLocation() {
      this.setValidateDropTime();
      if (this.isTimeValid01) {
        this.getDropLocation();
      }
    },

    setValidateDepTime() {
      var systemDepDate = moment(this.ValidDepDateTime).format("YYYYMMDDHHmm");
      var currentDepDate = moment(this.depDateTime).format("YYYYMMDDHHmm");

      if (parseInt(currentDepDate) >= parseInt(systemDepDate)) {
        this.isTimeValid02 = true;
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          title: this.isLanguageEN
            ? "Please choose a valid date and time."
            : "الرجاء اختيار تاريخ ووقت صالحين.",
          // title:
          //   "Please choose a valid date time. The minimum date time is" +
          //   moment(this.systemDepDate).format("YYYY-MM-DD HH:mm"),
          icon: "error",
        });
        this.isTimeValid02 = false;
      }
    },

    setValidateDropTime() {
      var systemDropDate = moment(this.ValidDropDateTime).format(
        "YYYYMMDDHHmm"
      );
      var maxDropOffDate = moment(this.ValidMaxDropDateTime).format(
        "YYYYMMDDHHmm"
      );

      var currentDropDate = moment(this.DropDateTime).format("YYYYMMDDHHmm");

      if (
        parseInt(maxDropOffDate) >= parseInt(currentDropDate) &&
        parseInt(currentDropDate) >= parseInt(systemDropDate)
      ) {
        this.isTimeValid01 = true;
      } else {
        Swal.fire({
          title: this.isLanguageEN
            ? "<strong> Please choose a valid date and time </strong>"
            : "<strong> " + "الرجاء اختيار تاريخ ووقت صالحين" + " </strong>",
          html: this.isMaxLabel
            ? this.isLanguageEN
              ? "In order to provide a better service, we only can operate  <br/> <b>" +
                `${moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm")}` +
                "</b> to <b>" +
                `${moment(this.ValidMaxDropDateTime).format(
                  "YYYY-MM-DD HH:mm"
                )}` +
                "</b>  <br/> based on your departure date. If you wish to know how we calculate time. Please check our <a href='#'> FAQ.</a> section. <br/>Thank you."
              : "من أجل تقديم خدمة أفضل ، يمكننا العمل فقط" +
                "  <br/> <b>" +
                `${moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm")}` +
                "</b> " +
                "إلى" +
                " <b>" +
                `${moment(this.ValidMaxDropDateTime).format(
                  "YYYY-MM-DD HH:mm"
                )}` +
                "</b>  <br/> " +
                "بناءً على تاريخ مغادرتك. إذا كنت ترغب في معرفة كيفية حساب الوقت. يرجى مراجعة" +
                " <a href='#'> FAQ.</a> " +
                "الجزء." +
                " <br/>" +
                "شكرًا لك."
            : this.isLanguageEN
            ? "In order to provide a better service, we only can drop your baggages on <br/> <b>" +
              `${moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm")}` +
              "</b>  <br/> based on your departure date. If you wish to know how we calculate time. Please check our <a href='#'> FAQ.</a> section. <br/>Thank you."
            : "من أجل تقديم خدمة أفضل ، يمكننا فقط إسقاط أمتعة الأمتعة" +
              " <br/> <b>" +
              `${moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm")}` +
              "</b>  <br/> " +
              "بناءً على تاريخ مغادرتك. إذا كنت ترغب في معرفة كيفية حساب الوقت. يرجى مراجعة" +
              " <a href='#'> FAQ.</a> " +
              "الجزء." +
              " <br/>" +
              "شكرًا لك.",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.isLanguageEN
            ? '<i class="fa fa-thumbs-up"></i> Reset The Time!'
            : '<i class="fa fa-thumbs-up"></i> ' + "إعادة ضبط الوقت!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              this.isLanguageEN
                ? "Time has been reset."
                : "تمت إعادة ضبط الوقت",
              ".",
              "success"
            );
            this.DropDateTime = this.ValidDropDateTime;
            this.isTimeValid01 = true;
          }
        });

        // Swal.fire({
        //   title:
        //     "In order to provide a better service, we only can operate <b>" +
        //     `${moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm")}` +
        //     "</b> to <b>" +
        //     `${moment(this.ValidMaxDropDateTime).format("YYYY-MM-DD HH:mm")}` +
        //     "</b> based on your departure date. If you wish to know how we calculate time, please check our <a href='#'> FAQ </a> section. <br/>Thank you.",
        //   showDenyButton: false,
        //   showCancelButton: false,
        //   confirmButtonText: "Reset The Time",
        // }).then((result) => {
        //   /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     Swal.fire("Time has been reset.", "", "success");
        //     this.DropDateTime = this.ValidDropDateTime;
        //   }
        // });
        // VueSweetalert2.fire({
        //   toast: true,
        //   position: "top-end",
        //   showConfirmButton: false,
        //   timer: 3000,
        //   title: "Please choose a valid date and time",
        //   // title:
        //   //   "Please choose a valid date time.The maximum date time is " +
        //   //   moment(this.ValidMaxDropDateTime).format("YYYY-MM-DD HH:mm") +
        //   //   ", and the minimum date time is " +
        //   //   moment(this.ValidDropDateTime).format("YYYY-MM-DD HH:mm"),

        //   icon: "error",
        // });
        this.isTimeValid01 = false;
      }
    },
  },
};
</script>

<style scoped>
#successFullSection {
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 100%;
  z-index: 99;
}

#loading {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 25%; */
}
.btn-previous {
  background: #ffffff;
  color: #595f69;
  width: 110px;
  height: 38px;
  border: 1px solid #e8e9eb;
  border-radius: 13px;
  box-shadow: none !important;
}

.btn-next {
  background: #000080;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 13px;
  box-shadow: none !important;
}
.btn-next:hover {
  color: #fff;
  background: #000080;
}
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.container-fluid {
  padding: 0px 140px 20px 140px;
}
.mapDiv {
  border-radius: 15px;
  height: 300px;
  border: 1px solid #e8e9eb;
  margin-top: 15px;
  margin-bottom: 20px;
}

input[type="text"] {
  border-radius: 13px;
  width: 100%;
}

input:focus {
  outline: none;
  border-color: #006203;
  box-shadow: none;
  border-radius: 13px;
  width: 100%;
}

select:focus {
  outline: none;
  border-color: #006203;
  box-shadow: none;
}

select {
  width: 100%;
  border-radius: 13px;
}

select:last-child {
  width: 100%;
  border-radius: 13px;
}

.fromDiv {
  width: 100%;
  padding: 0px 75px 0px 75px;
}

.fromDivSec {
  justify-content: start;
}
.label-front {
  font-size: 12px;
}
.notification-card {
  position: absolute !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background: #fff !important;
  background-clip: border-box !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem !important;
  z-index: 99999 !important;
  max-width: 463px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  -webkit-box-shadow: 10px 10px 300px -83px rgba(0, 0, 0, 1);
  -moz-box-shadow: 10px 10px 300px -83px rgba(0, 0, 0, 1);
  box-shadow: 10px 10px 300px -83px rgba(0, 0, 0, 1);
  animation: pop 0.3s linear 1;
}
@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

.info__padding {
  padding: 40px 40px 30px 40px;
}
.notification-card h3 {
  font-size: 19px;
  margin-bottom: 12px;
  font-weight: 600;
}
.notification-card p {
  font-size: 16px;
  font-weight: 400;
}
.notification-card h3,
p {
  color: #0b1a28;
  text-align: center;
}
.button__group button {
  color: #007aff;
  color: #fff;
  background-color: #000080;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: none;
  border-top: 1px solid rgb(220, 224, 226);
  /* border-left: 1px solid rgb(220, 224, 226); */
  padding: 18px 10px;
  cursor: pointer;
  float: left;
  width: 100%;
}
.button__group:after {
  content: "";
  clear: both;
  display: table;
}
.button__group button:not(:last-child) {
  border-left: none;
}
/* .button__group button:hover {
  background-color: rgb(250, 250, 250);
}
.button__group button:active {
  background-color: rgb(245, 245, 245);
} */
@media (min-width: 1024px) and (max-width: 1260px) {
  .container-fluid {
    padding: 0px 180px 20px 100px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container-fluid {
    padding: 0px 70px 20px 70px;
  }
}

@media (min-width: 599px) and (max-width: 768px) {
  .container-fluid {
    padding: 0px 60px 20px 30px;
  }
  #dropOffSec {
    width: 511px !important;
  }
}
@media (min-width: 425px) and (max-width: 599px) {
  .container-fluid {
    padding: 0px 100px 20px 0px;
  }
  #dropOffSec {
    width: 346px !important;
  }
}

@media (min-width: 200px) and (max-width: 425px) {
  .container-fluid {
    padding: 0px 0px 20px 0px;
  }
  #dropOffSec {
    width: 346px !important;
  }
}
</style>
