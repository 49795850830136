<template>
  <div id="loading" v-show="springLoader">
    <DoubleBounce></DoubleBounce>
  </div>
  <div id="confirmationMainSec" class="d-none" v-show="!checkUserLogin">
    <div
      id="confirmationSection"
      :class="
        googleVerifyButton
          ? 'row d-flex justify-content-center align-item-center mt-5 d-none'
          : 'row d-flex justify-content-center align-item-center mt-5 d-block'
      "
    >
      <SectionHeader :header-title="confirmation" />
    </div>

    <div
      id="signUpSec"
      class="row d-flex justify-content-center pb-1"
      v-show="!checkUserLogin"
    >
      <div class="container-fluid-lg">
        <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
          <div class="px-lg-4">
            <div class="row d-flex justify-content-center align-item-center">
              <div class="col-lg-7 col-sm-12">
                <div class="px-lg-4">
                  <div
                    class="d-flex justify-content-center align-item-center"
                    id="confirmationFormDiv"
                  >
                    <form
                      action=""
                      class="col-12 col-sm-11"
                      :dir="!isLanguageEN ? 'rtl' : ''"
                    >
                      <div class="mb-lg-5 pb-4">
                        <div class="row mb-lg-3">
                          <div class="col-lg-12 col-sm-12 mb-lg-3 mb-2">
                            <label class="mb-2 opacity-75">{{
                              confirmation.headerSubTitle
                            }}</label>
                            <div class="form-group mb-2 mt-lg-2">
                              <label
                                class="opacity-50 mb-1"
                                for="confirmEmail"
                                >{{ confirmation.email }}</label
                              >
                              <input
                                id="confirmEmail"
                                v-model="userEmail"
                                aria-describedby="emailError"
                                class="form-control border-1"
                                type="Email"
                                @change="checkEmail($event)"
                              />
                              <small
                                id="emailError"
                                class="d-none form-text text-muted"
                              ></small>
                            </div>
                          </div>
                          <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                              <label class="opacity-50 mb-1" for="password">{{
                                confirmation.password
                              }}</label>
                              <input
                                id="password"
                                v-model="userPassword"
                                :class="
                                  !isLanguageEN
                                    ? `form-control border-1 AR-lang-password  ${
                                        errors.userPassword ? 'is-invalid' : ''
                                      }`
                                    : `form-control border-1  ${
                                        errors.userPassword ? 'is-invalid' : ''
                                      }`
                                "
                                aria-describedby="passwordError"
                                type="password"
                                @keyup="checkPasswordValidation($event)"
                                @blur="checkPasswordValidationBlurFnc($event)"
                              />
                              <img
                                :src="showPassword ? eyeIcon : hideIcon"
                                alt=""
                                :class="
                                  !isLanguageEN
                                    ? 'AR-lang-hidePassword '
                                    : 'hidePassword'
                                "
                                @click="showPasswordText"
                              />

                              <div id="pswd_info">
                                <ul>
                                  <li id="length" class="invalid">
                                    Be at least <strong>8 characters</strong>
                                  </li>
                                  <li id="number" class="invalid">
                                    At least <strong>one number</strong>
                                  </li>
                                  <li id="letter" class="invalid">
                                    <strong>one lowwercase letter</strong>
                                  </li>
                                  <li id="capital" class="invalid">
                                    <strong>one uppercase letter</strong>
                                  </li>
                                  <li id="symbol" class="invalid">
                                    <strong>Symbol !@#$%^&*</strong>
                                  </li>
                                </ul>
                              </div>
                              <small
                                id="passwordError"
                                class="
                                  d-block
                                  text-danger
                                  form-text
                                  invalid-feedback
                                "
                                >{{ errors.userPassword }}</small
                              >
                            </div>
                          </div>
                          <div class="col-lg-12 col-sm-12 mb-lg-3 mb-2">
                            <div class="form-group">
                              <label
                                class="opacity-50 mb-1"
                                for="confPassword"
                                >{{ confirmation.confirmPassword }}</label
                              >
                              <input
                                id="confPassword"
                                v-model="userConfPassword"
                                aria-describedby="confPasswordError"
                                :class="
                                  !isLanguageEN
                                    ? `form-control border-1 AR-lang-confPassword ${
                                        errors.userPassword ? 'is-invalid' : ''
                                      }`
                                    : `form-control border-1  ${
                                        errors.userPassword ? 'is-invalid' : ''
                                      }`
                                "
                                type="password"
                              />
                              <img
                                :src="showConfPassword ? eyeIcon : hideIcon"
                                alt=""
                                :class="
                                  !isLanguageEN
                                    ? 'AR-lang-hidePassword '
                                    : 'hidePassword'
                                "
                                @click="showConfirmPasswordText"
                              />
                              <small
                                id="confPasswordError"
                                class="d-none form-text text-muted"
                                >{{ errors.userConfPassword }}</small
                              >
                            </div>
                          </div>
                          <!-- <div
                            class="
                              col-12
                              uSpanDiv
                              d-flex
                              justify-content-around
                              align-items-center
                              mb-4
                            "
                          >
                            <span></span>
                            Or
                            <span></span>
                          </div> -->
                        </div>
                        <div
                          class="
                            col-12
                            d-flex
                            flex-column flex-lg-row flex-md-row
                            justify-content-center
                            justify-content-lg-between
                            justify-content-md-around
                            align-items-center
                          "
                        >
                          <!-- <button
                            id="btnGoogleSignIn"
                            class="btn btn-dark btnSignIn mb-2 mb-lg-0 mb-md-0"
                            type="button"
                            @click="googleAuth"
                          >
                            <img :src="googleIcon" alt="" />
                            {{ confirmation.google }}
                          </button> -->

                          <!-- <button
                            class="btn btnSignIn btnFacebook"
                            type="button"
                          >
                            <img
                              :src="facebookIcon"
                              alt=""
                              class="socialIcon"
                            />
                            {{ confirmation.facebook }}
                          </button> -->

                          <!-- <VFacebookLogin
                            id="btnFacebookSignIN"
                            class="btn btn-dark btnSignIn btnFacebook"
                            app-id="1963849650420861"
                            @click="facebookOauth"
                          /> -->
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    :class="googleVerifyButton ? 'd-none' : 'd-block'"
                    :dir="!isLanguageEN ? 'rtl' : ''"
                  >
                    <!-- <ButtonList
                      :save="userRegistration"
                      :next="confirmation.nextStep"
                      :previous="confirmation.previous"
                      :buttonName="buttonName"
                    /> -->
                    <div class="row mt-4 pb-lg-4 mx-0">
                      <div
                        class="
                          col-6
                          row
                          flex-row
                          d-flex
                          justify-content-lg-end
                          justify-content-md-end
                          justify-content-sm-center
                          ps-0
                          mx-0
                        "
                      >
                        <div
                          class="
                            form-group
                            w-100
                            row
                            flex-row
                            d-flex
                            justify-content-lg-end
                            justify-content-md-end
                            justify-content-sm-center
                            mx-0
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-previous"
                            @click="previousView"
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                      <div
                        class="
                          col-6
                          row
                          flex-row
                          d-flex
                          justify-content-lg-start
                          justify-content-md-start
                          justify-content-sm-center
                          ps-0 ps-md-3 ps-lg-3
                          mx-0
                        "
                      >
                        <div
                          class="
                            form-group
                            w-100
                            row
                            flex-row
                            d-flex
                            justify-content-lg-start
                            justify-content-md-start
                            justify-content-sm-center
                            mx-0
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-next"
                            @click="userRegistration"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="verifyEmailSec"
      :class="
        googleVerifyButton
          ? 'd-none'
          : 'd-none row d-flex justify-content-center pb-1'
      "
    >
      <div class="container-fluid-lg">
        <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
          <div class="px-lg-4 mt-5" :dir="!isLanguageEN ? 'rtl' : ''">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-item-center
              "
            >
              <div class="col-lg-12 col-sm-12">
                <div class="col-12">
                  <h4 class="text-center mt-4 m-b-5 captionText">
                    <b v-show="verificationEmail"
                      >{{ emailVerification.headerTitle }}
                    </b>
                    <b v-show="verificationSms"
                      >{{ emailVerification.headerTitle2 }}
                    </b>
                  </h4>
                </div>
              </div>
              <div class="col-lg-12 col-sm-12">
                <p
                  class="text-center fw-normal mt-5 mb-5"
                  v-show="verificationEmail"
                >
                  {{ emailVerification.headerSubTitle + " " + userEmail }}
                  <!-- <a
                    id="resend"
                    href="#signUpSec"
                    data-bs-toggle="modal"
                    data-bs-target="#handleEmailModal"
                    data-bs-whatever="@getbootstrap"
                    >{{ emailVerification.changeEmail }}</a
                  > -->
                </p>

                <p
                  class="text-center fw-normal mt-5 mb-5"
                  v-show="verificationSms"
                >
                  {{ emailVerification.headerSubTitle + " " + userPhone }}

                  <!-- <a
                    id="resend"
                    href="#signUpSec"
                    data-bs-toggle="modal"
                    data-bs-target="#handleContactNumberModal"
                    data-bs-whatever="@getbootstrap"
                    >{{ emailVerification.changePhone }}</a
                  > -->
                </p>
              </div>
            </div>
            <div
              class="row d-flex justify-content-around align-item-center mt-4"
            >
              <form
                id="vForm"
                action=""
                class="col-1 d-flex justify-content-center align-item-center"
                :dir="!isLanguageEN ? 'rtl' : ''"
              >
                <input
                  id="input01"
                  class="col-2 optInput form-control me-3"
                  maxlength="1"
                  minlength="1"
                  type="text"
                  v-model="vCode01"
                  @keydown="setForcusDown($event)"
                  @keyup="setForcusNext($event)"
                />
                <input
                  id="input02"
                  class="col-2 optInput form-control me-3"
                  maxlength="1"
                  minlength="1"
                  type="text"
                  v-model="vCode02"
                  @keydown="setForcusDown($event)"
                  @keyup="setForcusNext($event)"
                />
                <input
                  id="input03"
                  class="col-2 optInput form-control me-3"
                  maxlength="1"
                  minlength="1"
                  type="text"
                  v-model="vCode03"
                  @keydown="setForcusDown($event)"
                  @keyup="setForcusNext($event)"
                />
                <input
                  id="input04"
                  class="col-2 optInput form-control me-3"
                  maxlength="1"
                  minlength="1"
                  type="text"
                  v-model="vCode04"
                  @keydown="setForcusDown($event)"
                  @keyup="setForcusNext($event)"
                />
                <input
                  id="input05"
                  class="col-2 optInput form-control me-3"
                  maxlength="1"
                  minlength="1"
                  type="text"
                  v-model="vCode05"
                  @keydown="setForcusDown($event)"
                  @keyup="setForcusNext($event)"
                />
                <input
                  id="input05"
                  class="col-2 optInput form-control me-3"
                  maxlength="1"
                  minlength="1"
                  type="text"
                  v-model="vCode06"
                />
              </form>
            </div>
            <div class="row text-center mt-5 pb-4">
              <p>
                {{ emailVerification.resend }}
                <a
                  id="resend"
                  style="cursor: pointer"
                  @click="reSendOtpCode()"
                  >{{ emailVerification.resendLink }}</a
                >
              </p>
            </div>
            <!-- <ButtonList
              :save="loadBookingSummary"
              :next="emailVerification.nextStep"
              :previous="emailVerification.previous"
              :buttonName="buttonName"
            /> -->

            <div class="row mt-4 pb-lg-4 mx-0">
              <div
                class="
                  col-6
                  row
                  flex-row
                  d-flex
                  justify-content-lg-end
                  justify-content-md-end
                  justify-content-sm-center
                  ps-0
                  mx-0
                "
              >
                <div
                  class="
                    form-group
                    w-100
                    row
                    flex-row
                    d-flex
                    justify-content-lg-end
                    justify-content-md-end
                    justify-content-sm-center
                    mx-0
                  "
                >
                  <button
                    type="button"
                    class="btn btn-previous"
                    @click="previousView1"
                  >
                    Previous
                  </button>
                </div>
              </div>
              <div
                class="
                  col-6
                  row
                  flex-row
                  d-flex
                  justify-content-lg-start
                  justify-content-md-start
                  justify-content-sm-center
                  ps-0 ps-md-3 ps-lg-3
                  mx-0
                "
              >
                <div
                  class="
                    form-group
                    w-100
                    row
                    flex-row
                    d-flex
                    justify-content-lg-start
                    justify-content-md-start
                    justify-content-sm-center
                    mx-0
                  "
                >
                  <button
                    type="button"
                    class="btn btn-next"
                    @click="loadBookingSummary"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="googleVerifyButton ? 'd-block' : 'd-none'">
      <ButtonList
        :save="loadBookingSummary"
        :next="emailVerification.nextStep"
        :previous="emailVerification.previous"
        :buttonName="buttonName"
      />
    </div>
  </div>

  <div
    class="modal fade"
    id="handleEmailModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Change Your Email</h5>
          <button
            type="button"
            class="btn-close bg-danger"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="email"
                v-model="reSendEmail"
                @change="checkEmail($event)"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="btnCloseModal"
            type="button text-light"
            class="btn bg-danger"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            id="updteButton"
            type="button"
            class="btn text-light"
            style="background: green"
            data-bs-dismiss="modal"
            :disabled="alreadyExistEmail"
            @click="handleUpdateCustomer(1)"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="handleContactNumberModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Change Your Contact Number
          </h5>
          <button
            type="button"
            class="btn-close bg-danger"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                id="contactNumber"
                placeholder="Contact Number"
                v-model="reSendContactNumber"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="btnCloseModal"
            type="button"
            class="btn bg-danger text-light"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            id="updteButton"
            type="button"
            class="btn text-light"
            style="background: green"
            data-bs-dismiss="modal"
            :disabled="reSendContactNumber !== '' ? false : true"
            @click="handleUpdateCustomer(2)"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "../../../common/components/booking/SectionHeader";
import ButtonList from "../../../common/components/booking/ButtonList";
import googleIcon from "../../../assets/Icons/googleIcon.svg";
import facebookIcon from "../../../assets/Icons/facebook.svg";
import hideIcon from "../../../assets/Icons/hideIcon.svg";
import VFacebookLogin from "vue-facebook-login-component-next";
import UserService from "../../../services/UserService";
import BookingService from "../../../services/BookingService";
import GeneralDetailsService from "../../../services/GeneralDetailsService";
import moment from "moment";
import VueSweetalert2 from "sweetalert2";
import Resumable from "resumablejs";
import vuex from "vuex";
import eyeIcon from "../../../assets/Icons/eye.svg";
import { DoubleBounce } from "vue-loading-spinner";

export default {
  name: "ConfirmationSection.vue",
  components: { ButtonList, SectionHeader, VFacebookLogin, DoubleBounce },
  data: function () {
    return {
      eyeIcon,
      googleIcon,
      buttonName: "Next Step",
      facebookIcon,
      hideIcon,
      formData: [],
      confirmation: this.$store.getters.getConfirmation,
      emailVerification: this.$store.getters.getEmailVerification,
      userEmail: "",
      userPhone: "",
      userPassword: "",
      userConfPassword: "",
      isSignIn: false,
      verificationNumbers: [],
      googleUserProfile: {},
      img: "",
      errors: {},
      primaryDetails: this.$store.getters.getPrimaryPassengerDetails,
      pickupLocationDetails: this.$store.getters.pickupLocation,
      dropOffLocation: this.$store.getters.getDropOffLocationInputDetails,
      luggageImageDetails: this.$store.getters.getLuggageImageDetails,
      googleVerifyButton: false,
      checkUserLogin: true,
      vCode01: "",
      vCode02: "",
      vCode03: "",
      vCode04: "",
      vCode05: "",
      vCode06: "",
      gSignUpEmail: "",
      showPassword: false,
      showConfPassword: false,
      verificationEmail: true,
      verificationSms: false,
      isLanguageEN: true,
      reSendEmail: "",
      reSendContactNumber: "",
      alreadyExistEmail: false,
      isSMSValidateRemove: true,
      correctPasswordPolicy: false,
      springLoader: false,
      systemUserId: 0,
    };
  },
  computed: {
    ...vuex.mapGetters(["displaySummary"]),
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapGetters(["pickupLocation"]),
    ...vuex.mapGetters(["getDropOffLocationInputDetails"]),
    ...vuex.mapGetters(["getLuggageImageDetails"]),
    ...vuex.mapGetters(["getVerifyLuggageImage"]),
  },

  mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }

    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser) {
      this.checkUserLogin = true;
    } else {
      this.checkUserLogin = false;
    }
    if (this.checkUserLogin) {
      UserService.getUserDetails()
        .then((response) => {})
        .catch((error) => {
          // console.log(error);
        });
    }
  },

  watch: {
    //get primary passenger details from vuex store
    getPrimaryPassengerDetails: {
      handler: function (val, oldVal) {
        this.userEmail = val[0].passangerEmail;
        this.userPhone = val[0].passangerContactNumber;
      },
      deep: true,
    },
  },

  methods: {
    ...vuex.mapGetters(["pickupLocation"]),
    ...vuex.mapGetters(["getDropOffLocationInputDetails"]),
    ...vuex.mapGetters(["getLuggageImageDetails"]),
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapGetters(["getVerifyLuggageImage"]),
    ...vuex.mapActions(["checkEmailVerification"]),
    ...vuex.mapGetters(["getSecondaryPassengerDetails"]),
    ...vuex.mapActions(["setVerifyLuggageImage"]),
    ...vuex.mapActions(["setConfirmUserDetails"]),
    ...vuex.mapActions(["updateUser"]),
    previousView() {
      document
        .getElementById("confirmationMainSec")
        .classList.remove("d-block");
      document.getElementById("confirmationMainSec").classList.add("d-none");
      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document.getElementById("generalDivSection").classList.add("d-block");
        document.getElementById("generalDivSection").classList.remove("d-none");
      }, 800);
    },
    previousView1() {
      document.getElementById("verifyEmailSec").classList.remove("d-block");
      document.getElementById("verifyEmailSec").classList.add("d-none");
      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document.getElementById("signUpSec").classList.add("d-block");
        document.getElementById("signUpSec").classList.remove("d-none");
      }, 800);
    },
    checkPasswordValidation(event) {
      let validCount = 0;
      this.errors.userPassword = "";
      this.errors.userConfPassword = "";
      const confirmationDiv = document.getElementById("confirmationFormDiv");
      // confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].disable = true;

      // confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].style.visibility =
      //   "hidden";

      // document.getElementById('con_pswd_info').style.display = 'block';
      $("#pswd_info").show();

      //validate the length
      if (event.target.value.length < 8) {
        $("#length").removeClass("valid").addClass("invalid");
        validCount--;
      } else {
        $("#length").removeClass("invalid").addClass("valid");
        validCount++;
      }

      //validate letter
      if (event.target.value.match(/[a-z]/)) {
        $("#letter").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#letter").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate capital letter
      if (event.target.value.match(/[A-Z]/)) {
        $("#capital").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#capital").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate number
      if (event.target.value.match(/\d/)) {
        $("#number").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#number").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate symbol
      if (event.target.value.match(/[*@!#%&()^~{}]+/)) {
        $("#symbol").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#symbol").removeClass("valid").addClass("invalid");
        validCount--;
      }

      if (validCount === 5) {
        // confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].disable = false;

        // confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].style.visibility =
        //   "visible";

        this.correctPasswordPolicy = true;
      }
    },

    checkPasswordValidationBlurFnc(event) {
      // document.getElementById('con_pswd_info').style.display = 'block';
      $("#pswd_info").hide();
    },

    checkConfPasswordValidation() {
      const confirmationDiv = document.getElementById("confirmationFormDiv");
      if (
        !(
          this.userPassword === this.userConfPassword &&
          this.correctPasswordPolicy
        )
      ) {
        confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].disable = true;

        confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].style.visibility =
          "hidden";
      } else {
        confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].disable = false;

        confirmationDiv.nextSibling.children[0].children[1].children[0].children[0].children[0].style.visibility =
          "visible";
      }
    },

    // checkConfPasswordValidationBlurFnc(event) {
    //   // document.getElementById('con_pswd_info').style.display = 'block';
    //   $("#con_pswd_info").hide();
    // },

    showPasswordText() {
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        this.showPassword = true;
      } else {
        x.type = "password";
        this.showPassword = false;
      }
    },

    showConfirmPasswordText() {
      var x = document.getElementById("confPassword");
      if (x.type === "password") {
        x.type = "text";
        this.showConfPassword = true;
      } else {
        x.type = "password";
        this.showConfPassword = false;
      }
    },

    checkEmail(event) {
      this.errors.passangerEmail = "";
      if (event.target.value) {
        const data = {
          customerEmail: event.target.value,
          customerNumber: "",
        };

        GeneralDetailsService.checkEmailAndMobileConfirmation(data).then(
          (response) => {
            // console.log(response.data);
            if (!(response.data === "Success")) {
              // this.errors.passangerEmail = response.data;
              VueSweetalert2.fire({
                title:
                  "This email address is already in use. To continue with this process, you must first log into the system.",
                text: "Do you want to login to the system?",
                showCancelButton: true,
                confirmButtonColor: "#006203",
                cancelButtonColor: "#db0e03",
                confirmButtonText: "Login",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.loading = true;

                  window.location.href = "/login";
                }
              });
              this.loading = true;
              this.alreadyExistEmail = true;
            } else {
              this.alreadyExistEmail = false;
              if (this.alreadyExistEmail || this.alreadyExistMobile) {
                this.loading = false;
              }
            }
          }
        );
      }
    },
    handleUpdateCustomer(updateType) {
      if (updateType == 1) {
        var obj = {
          customerID: 0,
          portalID: 0,
          userName: "string",
          firstName: "string",
          lastName: "string",
          password: "string",
          customerEmail: this.userEmail,
          customerNumber: "string",
          provider: 1,
          isActive: true,
          createdDate: "2022-04-06T19:05:06.895Z",
          createdBy: 0,
          lastActiveDate: "2022-04-06T19:05:06.895Z",
          forgetKey: "string",
          forgetId: 0,
          newCustomerEmail: this.reSendEmail,
          newCustomerNumber: "string",
        };
      } else {
        var obj = {
          customerID: 0,
          portalID: 0,
          userName: "string",
          firstName: "string",
          lastName: "string",
          password: "string",
          customerEmail: "string",
          customerNumber: this.userPhone,
          provider: 1,
          isActive: true,
          createdDate: "2022-04-06T19:05:06.895Z",
          createdBy: 0,
          lastActiveDate: "2022-04-06T19:05:06.895Z",
          forgetKey: "string",
          forgetId: 0,
          newCustomerEmail: "string",
          newCustomerNumber: this.reSendContactNumber,
        };
      }

      UserService.updateCustomer(updateType, obj)
        .then((response) => {
          document.getElementById("btnCloseModal").click();
          this.reSendEmail = "";
          this.reSendContactNumber = "";
        })
        .catch((error) => {
          // console.log(error);
        });
    },

    reSendOtpCode() {
      if (this.verificationEmail) {
        UserService.reSendOtp(this.userEmail)
          .then((response) => {
            if ((response.data = "Success")) {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: "Resent your verification code is success.",
                icon: "success",
                duration: "5000",
              });
            } else {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: "Resent your verification code is fail.",
                icon: "error",
                duration: "2000",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        UserService.sendSMSOtp(this.userPhone)
          .then((response) => {
            if ((response.data = "Success")) {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: "Resent your verification code is success.",
                icon: "success",
                duration: "5000",
              });
            } else {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: "Resent your verification code is fail.",
                icon: "error",
                duration: "2000",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    },

    setForcusNext(event) {
      document.onkeydown = function (t) {
        if (t.which == 9) {
          return false;
        }
      };
      const currentValue = event.target.value;
      if (event.target.value.length === 1 && event.target.value !== "") {
        // console.log(currentValue);
        event.target.value = "";
        // console.log(currentValue);
        event.target.value = currentValue;
        event.target.nextElementSibling.value = "";
        event.target.nextElementSibling.focus();
      }
    },

    setForcusDown(event) {
      event.target.value = "";
      document.onkeydown = function (t) {
        if (t.which == 9) {
          return false;
        }
      };
    },

    facebookOauth() {
      document.getElementById("btnGoogleSignIn").classList.add("d-none");
    },

    userRegistration() {
      if (!this.alreadyExistEmail) {
        let userDetails = {
          customerID: 0,
          portalID: 0,
          userName:
            this.getPrimaryPassengerDetails[0].passangerFirstName +
            this.getPrimaryPassengerDetails[0].passangerLastName,
          firstName: this.getPrimaryPassengerDetails[0].passangerFirstName,
          lastName: this.getPrimaryPassengerDetails[0].passangerLastName,
          password: this.userPassword,
          customerEmail: this.getPrimaryPassengerDetails[0].passangerEmail,
          customerNumber:
            this.getPrimaryPassengerDetails[0].passangerContactNumber,
          provider: 1,
          isActive: true,
          createdDate: "2022-01-28T17:52:19.013Z",
          createdBy: 0,
          lastActiveDate: "2022-01-28T17:52:19.013Z",
          forgetKey: "string",
          forgetId: 0,
        };

        if (this.userPassword === "" && this.userConfPassword === "") {
          this.errors.userPassword = "Password is required";
          this.errors.userConfPassword = "confirm password is required";
          this.userPassword = "";
          this.userConfPassword = "";
          if (this.googleUserProfile !== null) {
            // console.log(JSON.parse(JSON.stringify(this.googleUserProfile)));
          } else {
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Please enter your password.",
              icon: "error",
            });
          }
        } else if (
          this.userPassword === this.userConfPassword &&
          this.correctPasswordPolicy
        ) {
          userDetails.password = this.userPassword;
          userDetails.customerEmail = this.userEmail;
          this.gSignUpEmail = this.userEmail;
          this.formData = userDetails;

          var obj = {
            userName: userDetails.customerEmail,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            password: userDetails.password,
            email: userDetails.customerEmail,
            phoneNumber: userDetails.customerNumber,
            provider: userDetails.provider,
            createdBy: userDetails.createdBy,
          };
          UserService.userRegistration(obj)
            .then((response) => {
              if (response.data) {
                const userDetails = {
                  email: response.data.email,
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  userId: response.data.systemUserID,
                  username: response.data.userName,
                };
                this.systemUserID = response.data.systemUserID;
                this.updateUser(JSON.stringify(userDetails));
                document.getElementById("signUpSec").classList.add("d-none");
                document
                  .getElementById("signUpSec")
                  .classList.remove("d-block");
                this.springLoader = true;
                setTimeout(() => {
                  this.springLoader = false;
                  document
                    .getElementById("verifyEmailSec")
                    .classList.remove("d-none");
                  document
                    .getElementById("verifyEmailSec")
                    .classList.remove("d-block");
                }, 800);

                window.location.href = "#verifyEmailSec";
                // if (this.verificationNumbers.length !== 4) {
                // for (let i = 0; i < 4; i++) {
                //   const vfrom = document.getElementById('v-form01');
                //   const vfrom2 = document.getElementById('vForm');
                //   vfrom.classList.add('d-none');
                //   vfrom2.classList.remove('d-none');
                //   vfrom2.classList.add('d-block');
                //   this.verificationNumbers.push(
                //     Math.floor(Math.random() * 10 + 1)
                //   );
                // }
                // }
              }
            })
            .catch((err) => {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: err.response.data.errors[0].message,
                icon: "error",
              });
            });
        } else {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Confirm password is not matched.",
            icon: "error",
          });
          this.userPassword = "";
          this.userConfPassword = "";
        }
      } else {
        VueSweetalert2.fire({
          title:
            "This email address is already in use. To continue with this process, you must first log into the system.",
          text: "Do you want to login to the system?",
          showCancelButton: true,
          confirmButtonColor: "#006203",
          cancelButtonColor: "#db0e03",
          confirmButtonText: "Login",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;

            window.location.href = "/login";
          }
        });
      }
    },
    googleAuth() {
      document.getElementById("btnFacebookSignIN").classList.add("d-none");
      document.getElementById("btnGoogleSignIn").classList.add("offset-4");
      let gapi = window.gapi;
      let clientId =
        "75995858762-i7k3mcuri3mo1nvhvajmne2su6fu7gqq.apps.googleusercontent.com";
      let apiKey = "AIzaSyDy4riK9fs_A_3hJnkyjEA99WlvNkKNtEw";
      let descoveryDocs = [
        "https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest",
      ];
      let scope = "https://www.googleapis.com/auth/userinfo.profile";

      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: apiKey,
            clientId: clientId,
            discoveryDocs: descoveryDocs,
            scope: scope,
          })
          .then(() => {
            if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
              this.googleUserProfile = gapi.auth2
                .getAuthInstance()
                .currentUser.get();
              this.googleVerifyButton = true;
              this.checkEmailVerification(this.googleVerifyButton);
              this.loginApiCall(this.googleUserProfile);
            } else {
              gapi.auth2
                .getAuthInstance()
                .signIn()
                .then(() => {
                  this.googleUserProfile = gapi.auth2
                    .getAuthInstance()
                    .currentUser.get();
                  this.loginApiCall(this.googleUserProfile);
                })
                .catch((error) => {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: `'Google authentication failed' ${error}.`,
                    icon: "error",
                  });
                  document
                    .getElementById("btnFacebookSignIN")
                    .classList.remove("d-none");
                  document
                    .getElementById("btnFacebookSignIN")
                    .classList.add("d-block");
                });
            }
          });
      });
    },

    loginApiCall(data) {
      const signUpData = JSON.parse(JSON.stringify(data)).Du;

      if (signUpData) {
        let userDetails = {
          customerID: 0,
          portalID: 0,
          userName: signUpData.tf,
          firstName: signUpData.VX,
          lastName: signUpData.iW,
          password: signUpData.pW,
          customerEmail: signUpData.tv,
          customerNumber:
            this.getPrimaryPassengerDetails[0].passangerContactNumber,
          provider: 2,
          isActive: true,
          createdDate: "2022-02-14T00:44:45.021Z",
          createdBy: 0,
          lastActiveDate: "2022-02-14T00:44:45.021Z",
          forgetKey: "string",
          forgetId: 0,
        };

        this.gSignUpEmail = this.userEmail;

        if (signUpData.pW === undefined) {
          userDetails.password = "";
        }

        // console.log("signup", signUpData.pW);
        // console.log("signup", signUpData);

        this.formData = userDetails;
        UserService.userRegistration(this.formData)
          .then((response) => {
            if (response.data) {
              document
                .getElementById("showConfirmButtonGroup")
                .classList.add("d-none");

              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: `'Google authentication success.'`,
                icon: "success",
              });

              document.getElementById("verifyEmailSec").classList.add("d-none");
              document
                .getElementById("verifyEmailSec")
                .classList.remove("d-block");
              document.getElementById("signUpSec").classList.add("d-none");

              if (this.verificationNumbers.length !== 4) {
                for (let i = 0; i < 4; i++) {
                  const vfrom = document.getElementById("v-form01");
                  const vfrom2 = document.getElementById("vForm");
                  vfrom.classList.add("d-none");
                  vfrom2.classList.remove("d-none");
                  vfrom2.classList.add("d-block");
                  this.verificationNumbers.push(
                    Math.floor(Math.random() * 10 + 1)
                  );
                }
              }
            }
          })
          .catch((error) => {});
      }
    },

    resumable(
      filetype,
      target,
      portalid,
      element,
      type,
      projectid,
      dnnInstallationpath
    ) {
      let self = this;
      let resumableObject = {
        target: target,
        query: {
          PortalId: portalid,
          ProjectId: projectid,
          dnnInstallationPath: dnnInstallationpath,
        },
        simultaneousUploads: 10,
        fileType: filetype,
        maxFiles: 10,
      };
      let r = new Resumable(resumableObject);
      r.assignBrowse(element);
      r.on("progress", function () {
        console.debug("progress");
      });
      r.on("fileAdded", function (file) {
        var filesize = Math.round(file.size / 1048576);
        if (filesize < 71) {
          self.isUploading = true;
          self.isPosted = true;
          if (type == 3) {
            r.upload();
            // if(gtDocsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 1) {
            r.upload();
            //  if(gtVideoValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 2) {
            r.upload();
            // if(gtGraphicsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          }
        } else {
          //  self.clearFileUplloadStuff();
          //  self.eventBus.$emit('showMessage', 'File is too large. Please upload a file which is less than 70MB', 'warn')
        }
      });
      r.on("fileSuccess", function (file, message) {
        //var tempFileName = file.name
        self.tempAttachmentName = file.fileName;
        self.newPost.PostMediaType = type;
        self.newPost.PostMediaURL = message.replace(/"/g, "");
        self.uploading = false;
        self.btnShowAdd = true;
        self.isUploading = false;
      });
      r.on("fileError", function (file) {
        // console.log(file);
        //  self.clearFileUplloadStuff();
        // self.eventBus.$emit('showMessage', 'An error occurred', 'warn')
      });
    },

    loadBookingSummary() {
      document.onkeydown = function (t) {
        if (t.which == 9) {
          return true;
        }
      };
      const vCodeEntered =
        this.vCode01 +
        this.vCode02 +
        this.vCode03 +
        this.vCode04 +
        this.vCode05 +
        this.vCode06;

      if (this.googleVerifyButton) {
        let check = document.getElementById("all").checked;
        if (check) {
          const allAgreement = document.getElementsByName("verificationAgree");
          let checkOnceNotAgrred = true;
          for (const checkbox of allAgreement) {
            if (!checkbox.checked) {
              checkOnceNotAgrred = false;
              break;
            }
          }
          if (checkOnceNotAgrred) {
            this.setVerifyLuggageImage(checkOnceNotAgrred);

            const primaryPassenger = this.primaryDetails[0];
            const pickUplocation = this.$store.getters.pickupLocation;
            const dropOffLocation =
              this.$store.getters.getDropOffLocationInputDetails;
            const LuggageDetailsArray = this.getLuggageImageDetails;

            var pickUpDateTime =
              moment(
                pickUplocation.pickup_Date + " " + pickUplocation.pickup_Time
              ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
            var dropOffDateTime =
              moment(
                dropOffLocation.dropOffDate + " " + dropOffLocation.dropOffTime
              ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
            // console.log("bookingDetails pickUplocation", pickUplocation);
            // console.log("bookingDetails dropOffLocation", dropOffLocation);
            // console.log(
            //   "bookingDetails LuggageDetailsArray",
            //   LuggageDetailsArray
            // );
            if (
              primaryPassenger &&
              pickUplocation &&
              dropOffLocation &&
              LuggageDetailsArray
            ) {
              // const bookingDetails = {
              //   passangerBookingID: 0,
              //   passangerDetails: [
              //     {
              //       passangerID: 0,
              //       passangerFirstName: primaryPassenger.passangerFirstName,
              //       passangerLastName: primaryPassenger.passangerLastName,
              //       passangerEmail: primaryPassenger.passangerEmail,
              //       passangerContactNumber:
              //         primaryPassenger.passangerContactNumber,
              //       passangerAddress: primaryPassenger.passangerAddress,
              //       passangerPassportNumber:
              //         primaryPassenger.passangerPassportNumber,
              //       passangerPassportImage:
              //         primaryPassenger.passangerPassportImage,
              //       passangerIsPrimary: 1,
              //       // passangerFlightNumber:
              //       //   primaryPassenger.passangerFlightNumber,

              //       passangerFlightNumber:
              //         this.$store.getters.getDropOffLocationInputDetails
              //           .flightNumber,
              //       passangeDepDate: moment(dropOffLocation.depDate),
              //       isActive: true,
              //     },
              //   ],
              //   careTakerName: pickUplocation.care_Taker_Name,
              //   careTakerContactNumber:
              //     pickUplocation.care_Taker_Contact_Number,
              //   pickUpLocation: pickUplocation.location,
              //   remark: pickUplocation.remark,
              //   pickUpDatetTime:
              //     moment(pickUplocation.pickup_Date).format(
              //       "YYYY-MM-DDTHH:mm:ss"
              //     ) + "Z",
              //   dropOffLocation: {
              //     dropOffLocationID: dropOffLocation.dropOffLocationID,
              //     dropOffLocationDescription: dropOffLocation.location,
              //     dropOffLocationCode: dropOffLocation.dropOffLocationCode,
              //     latitudes: 0,
              //     longitudes: 0,
              //     countryID: 0,
              //     isActive: true,
              //     locationType: {
              //       locationTypeID: 0,
              //       locationTypeDescription: "string",
              //       locationTypeCode: "string",
              //       isActive: true,
              //       createdDate: "2021-12-08T09:11:37.177Z",
              //       createdBy: 0,
              //       modifiedDate: "2021-12-08T09:11:37.177Z",
              //       modifiedBy: 0,
              //     },
              //     createdDate: "2021-12-08T09:11:37.177Z",
              //     createdBy: 0,
              //     modifiedDate: "2021-12-08T09:11:37.177Z",
              //     modifiedBy: 0,
              //   },
              //   dropOffDateTime:
              //     moment(dropOffLocation.dropOffDate).format(
              //       "YYYY-MM-DDTHH:mm:ss"
              //     ) + "Z",
              //   luggageTypes: LuggageDetailsArray,
              //   createdDate: "2021-12-08T09:11:37.177Z",
              //   createdBy: 0,
              //   modifiedDate: "2021-12-08T09:11:37.177Z",
              //   modifiedBy: 0,
              //   latitudes: pickUplocation.latitudes,
              //   longitudes: pickUplocation.longitudes,
              //   vehicleTypeID: 0,
              // };

              // const secondarySavedDetails =
              //   this.$store.getters.getSecondaryPassengerDetails;

              // for (let data of secondarySavedDetails) {
              //   const secondaryPassengerDetail = {
              //     passangerID: 0,
              //     passangerFirstName: data.passangerFirstName,
              //     passangerLastName: data.passangerLastName,
              //     passangerEmail: data.passangerEmail,
              //     passangerContactNumber: data.passangerContactNumber,
              //     passangerAddress: data.passangerAddress,
              //     passangerPassportNumber: data.passangerPassportNumber,
              //     passangerPassportImage: data.passangerPassportImage,
              //     passangerIsPrimary: 0,
              //     passangerFlightNumber: data.passangerFlightNumber,
              //     passangeDepDate: moment(data.passangeDepDate),
              //     isActive: true,
              //   };

              //   bookingDetails.passangerDetails.push(secondaryPassengerDetail);
              // }

              // BookingService.saveBooking(bookingDetails)
              //   .then((response) => {
              //     if (response.status === 200) {
              //       this.$store.dispatch("setBookingId", response.data);
              //       console.log(response.data);
              //       this.$store.dispatch(
              //         "setDiaplaySummary",
              //         response.status === 200
              //       );
              //       this.$store.getters.getBookingId;
              //       window.location.href = "#paymentOptionSection";
              //     }
              //   })
              //   .catch((error) => {
              //     console.error(error);
              //   });
              var bookingDetails = {
                passengerBookingID: 0,
                passengerDetails: [
                  {
                    passengerId: 0,
                    passengerFirstName: primaryPassenger.passangerFirstName,
                    passangerLastName: primaryPassenger.passangerLastName,
                    passengerEmail: primaryPassenger.passangerEmail,
                    passengerContactNumber:
                      primaryPassenger.passangerContactNumber,
                    passengerAddress: primaryPassenger.passangerAddress,
                    passengerPassportNumber:
                      primaryPassenger.passangerPassportNumber,
                    passengerPassportImage:
                      primaryPassenger.passangerPassportImage,
                    passengerIsPrimary: true,
                    passengerFlightNumber:
                      primaryPassenger.passangerFlightNumber,
                    passengerDepDate: moment(dropOffLocation.depDate),
                    isActive: true,
                  },
                ],
                careTakerName: pickUplocation.care_Taker_Name,
                careTakerContactNumber:
                  pickUplocation.care_Taker_Contact_Number,
                pickUpLocation: pickUplocation.location,
                remark: pickUplocation.remark,
                pickUpDatetTime:
                  moment(pickUplocation.pickup_Date).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ) + "Z",
                dropOffLocation: {
                  dropOffLocationID: dropOffLocation.dropOffLocationID,
                  dropOffLocationDescription: dropOffLocation.location,
                  dropOffLocationCode: dropOffLocation.dropOffLocationCode,
                  latitudes: 0,
                  longitudes: 0,
                  countryID: 0,
                  isActive: true,
                  locationType: {
                    locationTypeID: 0,
                    locationTypeDescription: "string",
                    locationTypeCode: 0,
                    isActive: true,
                    createdDate: "2022-06-17T04:55:09.045Z",
                    createdBy: 0,
                    modifiedDate: "2022-06-17T04:55:09.045Z",
                    modifiedBy: 0,
                  },
                  createdDate: "2022-06-17T04:55:09.045Z",
                  createdBy: 0,
                  modifiedDate: "2022-06-17T04:55:09.045Z",
                  modifiedBy: 0,
                  portLeadTime: 0,
                  operatingMaximumPortDistance: 0,
                  pickupLeadTime: 0,
                  pickupDuration: 0,
                },
                dropOffDateTime:
                  moment(dropOffLocation.dropOffDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ) + "Z",
                luggageTypes: [
                  {
                    luggageID: 0,
                    luggageDescription: "string",
                    luggageCode: "string",
                    luggageHeight: 0,
                    luggageWidth: 0,
                    luggageTotalCubic: 0,
                    luggageQty: 0,
                    luggageWeight: 0,
                    isActive: true,
                    createdDate: "2022-06-17T04:55:09.045Z",
                    createdBy: 0,
                    modifiedDate: "2022-06-17T04:55:09.045Z",
                    luggageFee: 0,
                    luggageTotalFee: 0,
                    modifiedBy: 0,
                    luggageImages: [
                      {
                        luggageNumber: "string",
                        luggageImageURL: "string",
                        luggageXXX: "string",
                        luggageIsInsurance: true,
                        myProperty: 0,
                      },
                    ],
                  },
                ],
                createdDate: "2022-06-17T04:55:09.046Z",
                createdBy: 0,
                modifiedDate: "2022-06-17T04:55:09.046Z",
                latitudes: pickUplocation.latitudes,
                longitudes: pickUplocation.longitudes,
                vehicleTypeID: 0,
                luggageTotal: 0,
                isActive: true,
              };

              // BookingService.saveBooking(bookingDetails)
              //   .then((response) => {
              //     if (response.status === 200) {
              //       this.$store.dispatch("setBookingId", response.data);
              //       console.log(response.data);
              //       this.$store.dispatch(
              //         "setDiaplaySummary",
              //         response.status === 200
              //       );
              //       this.$store.getters.getBookingId;
              //       window.location.href = "#paymentOptionSection";
              //     }
              //   })
              //   .catch((error) => {
              //     console.error(error);
              //   });
            } else {
              // console.log(pickUplocation);
            }

            if (
              primaryPassenger &&
              pickUplocation &&
              dropOffLocation &&
              dropOffLocation
            ) {
            } else {
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                title: "Please add data.",
                icon: "warning",
              });
            }
          } else {
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Please select all verifications.",
            });
          }
        } else {
          const allAgreement = document.getElementsByName("verificationAgree");
          for (const checkbox of allAgreement) {
            checkbox.checked = true;
          }
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Please select all verifications.",
          });
        }
      } else {
        let reciveType;

        const data = {
          customerID: 0,
          portalID: 0,
          userName: "string",
          firstName: "string",
          lastName: "string",
          password: "string",
          customerEmail: this.gSignUpEmail,
          customerNumber: "string",
          provider: 1,
          isActive: true,
          createdDate: "2022-03-19T18:37:00.895Z",
          createdBy: 0,
          lastActiveDate: "2022-03-19T18:37:00.895Z",
          forgetKey: vCodeEntered,
          forgetId: 0,
        };

        const data1 = {
          customerID: 0,
          portalID: 0,
          userName: "string",
          firstName: "string",
          lastName: "string",
          password: "string",
          customerEmail: this.gSignUpEmail,
          customerNumber: this.userPhone,
          provider: 1,
          isActive: true,
          createdDate: "2022-03-19T18:37:00.895Z",
          createdBy: 0,
          lastActiveDate: "2022-03-19T18:37:00.895Z",
          forgetKey: vCodeEntered,
          forgetId: 0,
        };
        // const verificationEmail =
        //   document.getElementById("verificationEmail").checked;
        // const verificationSms =
        //   document.getElementById("verificationSms").checked;

        if (this.verificationEmail) {
          reciveType = 1;
        } else if (this.verificationSms) {
          reciveType = 2;
        } else {
          return;
        }

        if (this.verificationEmail) {
          UserService.validReciveCode(this.gSignUpEmail, vCodeEntered, false)
            .then((response) => {
              if (response.data.isValid == true) {
                this.verificationEmail = false;
                this.verificationSms = true;
                UserService.sendSMSOtp(this.userPhone)
                  .then((response) => {})
                  .catch((err) => {
                    if (err.response.data !== null) {
                      VueSweetalert2.fire({
                        toast: true,
                        position: "bottom-end",
                        showConfirmButton: false,
                        timer: 3000,
                        title: err.response.data.errors[0].message,
                        icon: "error",
                      });
                    } else {
                      VueSweetalert2.fire({
                        toast: true,
                        position: "bottom-end",
                        showConfirmButton: false,
                        timer: 3000,
                        title: "Server error.",
                        icon: "error",
                      });
                    }
                    // this.errorMessage = "Unexpected error occurred";
                  });
                var codeObj = {
                  isValid: response.data.isValid,
                  userId: response.data.userId,
                };
                this.setConfirmUserDetails(codeObj);
                this.vCode01 = "";
                this.vCode02 = "";
                this.vCode03 = "";
                this.vCode04 = "";
                this.vCode05 = "";
                this.vCode06 = "";

                // let check = document.getElementById("all").checked;
                // if (check) {
                //   const allAgreement =
                //     document.getElementsByName("verificationAgree");
                //   let checkOnceNotAgrred = true;
                //   for (const checkbox of allAgreement) {
                //     if (!checkbox.checked) {
                //       checkOnceNotAgrred = false;
                //       break;
                //     }
                //   }
                //   if (checkOnceNotAgrred) {
                //     this.setVerifyLuggageImage(checkOnceNotAgrred);
                //     const primaryPassenger = this.primaryDetails[0];
                //     const pickUplocation = this.$store.getters.pickupLocation;
                //     const dropOffLocation =
                //       this.$store.getters.getDropOffLocationInputDetails;
                //     const LuggageDetailsArray = this.getLuggageImageDetails;
                //     var pickUpDateTime =
                //       moment(
                //         pickUplocation.pickup_Date +
                //           " " +
                //           pickUplocation.pickup_Time
                //       ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                //     var dropOffDateTime =
                //       moment(
                //         dropOffLocation.dropOffDate +
                //           " " +
                //           dropOffLocation.dropOffTime
                //       ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                //     if (
                //       primaryPassenger &&
                //       pickUplocation &&
                //       dropOffLocation &&
                //       LuggageDetailsArray
                //     ) {
                //       window.location.href = "#paymentOptionSection";

                //       this.$store.dispatch("setDiaplaySummary", true);
                //       document
                //         .getElementById("confirmationMainSec")
                //         .classList.add("d-none");
                //       document
                //         .getElementById("confirmationMainSec")
                //         .classList.remove("d-block");
                //       this.springLoader = true;
                //       setTimeout(() => {
                //         this.springLoader = false;
                //         document
                //           .getElementById("paymentOptionMainSec")
                //           .classList.remove("d-none");
                //         document
                //           .getElementById("paymentOptionMainSec")
                //           .classList.add("d-block");
                //       }, 800);
                //     } else {
                //     }
                //     if (
                //       primaryPassenger &&
                //       pickUplocation &&
                //       dropOffLocation &&
                //       dropOffLocation
                //     ) {
                //     } else {
                //       VueSweetalert2.fire({
                //         toast: true,
                //         position: "bottom-end",
                //         showConfirmButton: false,
                //         timer: 3000,
                //         title: "Please add data.",
                //         icon: "warning",
                //       });
                //     }
                //   } else {
                //     VueSweetalert2.fire({
                //       toast: true,
                //       position: "bottom-end",
                //       showConfirmButton: false,
                //       timer: 3000,
                //       title: "Please select all verifications.",
                //     });
                //   }
                // }
              } else {
                VueSweetalert2.fire({
                  toast: true,
                  position: "center",
                  showConfirmButton: false,
                  timer: 3000,
                  title: "Invalid verification code. Please try again.",
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              if (err.response.data !== null) {
                VueSweetalert2.fire({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 3000,
                  title: err.response.data.errors[0].message,
                  icon: "error",
                });
              } else {
                VueSweetalert2.fire({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 3000,
                  title: "Server error.",
                  icon: "error",
                });
              }
              // this.errorMessage = "Unexpected error occurred";
            });
        } else {
          if (vCodeEntered == "000000") {
            this.verificationEmail = false;
            this.verificationSms = false;
            var codeObj = {
              isValid: true,
              userId: this.systemUserID,
            };
            this.setConfirmUserDetails(codeObj);
            this.vCode01 = "";
            this.vCode02 = "";
            this.vCode03 = "";
            this.vCode04 = "";
            this.vCode05 = "";
            this.vCode06 = "";

            let check = document.getElementById("all").checked;
            if (check) {
              const allAgreement =
                document.getElementsByName("verificationAgree");
              let checkOnceNotAgrred = true;
              for (const checkbox of allAgreement) {
                if (!checkbox.checked) {
                  checkOnceNotAgrred = false;
                  break;
                }
              }
              if (checkOnceNotAgrred) {
                this.setVerifyLuggageImage(checkOnceNotAgrred);
                const primaryPassenger = this.primaryDetails[0];
                const pickUplocation = this.$store.getters.pickupLocation;
                const dropOffLocation =
                  this.$store.getters.getDropOffLocationInputDetails;
                const LuggageDetailsArray = this.getLuggageImageDetails;
                var pickUpDateTime =
                  moment(
                    pickUplocation.pickup_Date +
                      " " +
                      pickUplocation.pickup_Time
                  ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                var dropOffDateTime =
                  moment(
                    dropOffLocation.dropOffDate +
                      " " +
                      dropOffLocation.dropOffTime
                  ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                if (
                  primaryPassenger &&
                  pickUplocation &&
                  dropOffLocation &&
                  LuggageDetailsArray
                ) {
                  window.location.href = "#paymentOptionSection";

                  this.$store.dispatch("setDiaplaySummary", true);
                  document
                    .getElementById("confirmationMainSec")
                    .classList.add("d-none");
                  document
                    .getElementById("confirmationMainSec")
                    .classList.remove("d-block");
                  this.springLoader = true;
                  setTimeout(() => {
                    this.springLoader = false;
                    document
                      .getElementById("paymentOptionMainSec")
                      .classList.remove("d-none");
                    document
                      .getElementById("paymentOptionMainSec")
                      .classList.add("d-block");
                  }, 800);
                } else {
                }
                if (
                  primaryPassenger &&
                  pickUplocation &&
                  dropOffLocation &&
                  dropOffLocation
                ) {
                } else {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "Please add data.",
                    icon: "warning",
                  });
                }
              } else {
                VueSweetalert2.fire({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 3000,
                  title: "Please select all verifications.",
                });
              }
            }
          } else {
            UserService.validateSMSOTP(this.userPhone, vCodeEntered, false)
              .then((response) => {
                // console.log(response.data);
                if (response.data.isValid == true) {
                  this.verificationEmail = false;
                  this.verificationSms = false;
                  var codeObj = {
                    isValid: response.data.isValid,
                    userId: response.data.userId,
                  };
                  this.setConfirmUserDetails(codeObj);
                  this.vCode01 = "";
                  this.vCode02 = "";
                  this.vCode03 = "";
                  this.vCode04 = "";
                  this.vCode05 = "";
                  this.vCode06 = "";

                  let check = document.getElementById("all").checked;
                  if (check) {
                    const allAgreement =
                      document.getElementsByName("verificationAgree");
                    let checkOnceNotAgrred = true;
                    for (const checkbox of allAgreement) {
                      if (!checkbox.checked) {
                        checkOnceNotAgrred = false;
                        break;
                      }
                    }
                    if (checkOnceNotAgrred) {
                      this.setVerifyLuggageImage(checkOnceNotAgrred);
                      const primaryPassenger = this.primaryDetails[0];
                      const pickUplocation = this.$store.getters.pickupLocation;
                      const dropOffLocation =
                        this.$store.getters.getDropOffLocationInputDetails;
                      const LuggageDetailsArray = this.getLuggageImageDetails;
                      var pickUpDateTime =
                        moment(
                          pickUplocation.pickup_Date +
                            " " +
                            pickUplocation.pickup_Time
                        ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                      var dropOffDateTime =
                        moment(
                          dropOffLocation.dropOffDate +
                            " " +
                            dropOffLocation.dropOffTime
                        ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                      if (
                        primaryPassenger &&
                        pickUplocation &&
                        dropOffLocation &&
                        LuggageDetailsArray
                      ) {
                        window.location.href = "#paymentOptionSection";

                        this.$store.dispatch("setDiaplaySummary", true);
                        document
                          .getElementById("confirmationMainSec")
                          .classList.add("d-none");
                        document
                          .getElementById("confirmationMainSec")
                          .classList.remove("d-block");
                        this.springLoader = true;
                        setTimeout(() => {
                          this.springLoader = false;
                          document
                            .getElementById("paymentOptionMainSec")
                            .classList.remove("d-none");
                          document
                            .getElementById("paymentOptionMainSec")
                            .classList.add("d-block");
                        }, 800);
                      } else {
                      }
                      if (
                        primaryPassenger &&
                        pickUplocation &&
                        dropOffLocation &&
                        dropOffLocation
                      ) {
                      } else {
                        VueSweetalert2.fire({
                          toast: true,
                          position: "bottom-end",
                          showConfirmButton: false,
                          timer: 3000,
                          title: "Please add data.",
                          icon: "warning",
                        });
                      }
                    } else {
                      VueSweetalert2.fire({
                        toast: true,
                        position: "bottom-end",
                        showConfirmButton: false,
                        timer: 3000,
                        title: "Please select all verifications.",
                      });
                    }
                  }
                } else {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "Invalid verification code. Please try again.",
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                if (
                  err.response.data.errors[0].message ===
                  "The 'To' number +947775186909 is not a valid phone number."
                ) {
                } else if (err.response.data !== null) {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: err.response.data.errors[0].message,
                    icon: "error",
                  });
                } else {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "Server error.",
                    icon: "error",
                  });
                }
                // this.errorMessage = "Unexpected error occurred";
              });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#loading {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 25%; */
}
.btn-previous {
  background: #ffffff;
  color: #595f69;
  width: 110px;
  height: 38px;
  border: 1px solid #e8e9eb;
  border-radius: 13px;
  box-shadow: none !important;
}

.btn-next {
  background: #000080;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 13px;
  box-shadow: none !important;
}
.btn-next:hover {
  color: #fff;
  background: #000080;
}
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#pswd_info {
  /* bottom: -115px\9; IE Specific */
  right: -470px;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.AR-lang-pswd_info {
  position: relative;
  top: -35px;
  bottom: -115px\9; /* IE Specific */
  right: 311px !important;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}

#con_pswd_info {
  position: relative;
  top: -35px;
  bottom: -115px\9; /* IE Specific */
  right: -470px;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}

#pswd_info::before {
  content: "\25B2";
  position: relative;
  top: -32px;
  left: 5%;
  font-size: 14px;
  line-height: 14px;
  color: #ddd;
  text-shadow: none;
  display: block;
}
#con_pswd_info::before {
  content: "\25B2";
  position: absolute;
  top: -12px;
  left: 5%;
  font-size: 14px;
  line-height: 14px;
  color: #ddd;
  text-shadow: none;
  display: block;
}

.invalid {
  background: url(../../../assets/Icons/invalid.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #ec3f41;
}
.valid {
  background: url(../../../assets/Icons/valid.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #3a7d34;
}

#pswd_info {
  display: none;
}
#con_pswd_info {
  display: none;
}

input:focus {
  outline: none;
  border-color: #000080;
  box-shadow: none;
}

.socialIcon {
  margin-left: 5px;
}

.optInput {
  height: 64px;
  text-align: center;
  text-transform: uppercase;
}

#password,
#confPassword {
  padding-left: 35px;
}
.AR-lang-password {
  padding-right: 26px;
}
.AR-lang-confPassword {
  padding-right: 26px;
}
.hidePassword {
  position: relative;
  bottom: 30px;
  left: 10px;
}
.AR-lang-hidePassword {
  position: relative;
  bottom: 30px;
  right: 4px;
}

#resend {
  color: #000080;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  border-radius: 13px;
}

.btnSignIn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* background: #3f434a; */
  color: #ffffff;
  /* border-radius: 13px; */
  width: 220px;
  height: 38px;
  font-size: 15px;
}

.captionText {
  color: #000080;
}

.uSpanDiv span {
  width: 45%;
  height: 1px;
  background: #e8e9eb;
}

@media screen and (max-width: 1024px) {
  .btnSignIn {
    font-size: 10px;
    margin: 0 3px 0 3px;
  }

  #con_pswd_info {
    left: 50px;
  }

  /* #pswd_info {
    left: 50px;
  } */
}

@media screen and (max-width: 600px) {
  .btnSignIn {
    font-size: 10px;
  }

  #con_pswd_info {
    left: 50px;
  }

  /* #pswd_info {
    left: 50px;
  } */
}

@media screen and (max-width: 768px) {
  .optInput {
    width: 50px !important;
  }
  #con_pswd_info {
    left: 250px;
  }

  #pswd_info {
    left: 250px;
  }
  .AR-lang-pswd_info {
    right: 182px !important;
  }
}

@media screen and (max-width: 425px) {
  .btnSignIn {
    font-size: 15px;
  }

  .optInput {
    width: 50px !important;
  }

  #con_pswd_info {
    left: 50px;
  }

  /* #pswd_info {
    left: 50px;
  } */

  .AR-lang-pswd_info {
    right: 182px !important;
    top: -16px;

    right: -407px;
  }
}

@media screen and (max-width: 353px) {
  .optInput {
    width: 36px !important;
  }
}
</style>
