<template>
  <div class="row paymentCardDiv mt-3">
    <div id="paymentCard" class="card" style="width: 40rem">
      <div class="form-check mt-3">
        <input
          id="paymentCard"
          :value="payment.cardType"
          class="form-check-input"
          type="checkbox"
          name="paymentCard"
          @change="changeCheckedPaymentCardDivColor($event)"
        />
        <label id="paymentTypeLabel" class="form-check-label" for="paymentCard">
          {{ payment.cardType }}
        </label>
      </div>
      <div
        class="form-check d-flex justify-content-between align-items-end pb-4"
      >
        <label class="form-check-label" for="cardImg">
          {{ payment.description }}
        </label>
        <img id="cardImg" :src="creditCard" alt="" />
        <!--        <img id="cardImg" :src="payment.cardImg" alt="">-->
      </div>
    </div>
  </div>
</template>

<script>
import creditCard from "../../../assets/img/creditCard.png";
import VueSweetalert2 from "sweetalert2";

export default {
  name: "paymentCard",
  data: function () {
    return {
      creditCard,
    };
  },
  props: {
    payment: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    changeCheckedPaymentCardDivColor(event) {
      const allCards = document.querySelectorAll(".card");
      const paymentFormDiv = document.getElementById("paymentFormDiv");

      if (event.target.checked) {
        if (event.target.value === "Credit Card") {
          // event.target.parentElement.parentElement.classList.add(
          //   'addStyleCicked'
          // );
          // allCards[1].classList.add('d-none');
          // allCards[2].classList.add('d-none');
          // paymentFormDiv.classList.remove('d-none');
          VueSweetalert2.fire({
            title: "Oops...",
            text: "This feature is currently not available",
          });
          this.$store.dispatch("setPaymentType", "");
        } else if (event.target.value === "Cash") {
          event.target.parentElement.parentElement.classList.add(
            "addStyleCicked"
          );
          allCards[0].classList.add("d-none");
          allCards[2].classList.add("d-none");

          this.$store.dispatch("setPaymentType", event.target.value);

          setTimeout(() => {
            this.$store.dispatch("setPaymentType", "");
          }, 1000);
        } else {
          event.target.parentElement.parentElement.classList.add(
            "addStyleCicked"
          );
          allCards[0].classList.add("d-none");
          allCards[1].classList.add("d-none");

          this.$store.dispatch("setPaymentType", event.target.value);
        }
      } else {
        if (event.target.value === "Credit Card") {
          allCards[1].classList.remove("d-none");
          allCards[2].classList.remove("d-none");
          allCards[1].classList.add("d-block");
          allCards[2].classList.add("d-block");
          paymentFormDiv.classList.add("d-none");
        } else if (event.target.value === "Cash") {
          allCards[0].classList.remove("d-none");
          allCards[2].classList.remove("d-none");
          allCards[0].classList.add("d-block");
          allCards[2].classList.add("d-block");
          paymentFormDiv.classList.add("d-none");
          this.$store.dispatch("setPaymentType", "");
        } else {
          allCards[0].classList.remove("d-none");
          allCards[1].classList.remove("d-none");
          allCards[0].classList.add("d-block");
          allCards[1].classList.add("d-block");
          paymentFormDiv.classList.add("d-none");
          this.$store.dispatch("setPaymentType", "");
        }
        event.target.parentElement.parentElement.classList.remove(
          "addStyleCicked"
        );
        event.target.parentElement.parentElement.classList.add("card");
      }
    },
  },
};
</script>

<style scoped>
.addStyleCicked {
  border: 2px solid #000080;
  background: #ffffff;
}

#cardImg {
  width: 40px;
  height: 40px;
}

.paymentCardDiv .card {
  border-radius: 15px;
}

.paymentCardDiv .card:hover {
  background: #f8f8f8;
  border-radius: 15px;
}

.form-check-input:checked {
  background-color: #000080;
  border-color: #000080;
}
</style>
