<template>
  <div
    class="row d-flex justify-content-center align-item-center sectionHeader"
  >
    <div class="col-lg-12 d-flex justify-content-center align-item-center">
      <SmallCircle :text="headerTitle.headerCount" />
    </div>
    <h4 class="text-center mt-3 m-b-4">
      <b>{{ headerTitle.headerTitle }}</b>
    </h4>
  </div>
</template>

<script>
import SmallCircle from "./SmallCircle";

export default {
  name: "SectionHeader",
  components: {
    SmallCircle,
  },
  props: {
    headerTitle: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.sectionHeader {
  margin: 50px 0 60px 0;
  color: #000080;
}
</style>
