<template>
  <div id="loading" v-show="springLoader">
    <DoubleBounce></DoubleBounce>
  </div>
  <div id="luggageDetailsSection" class="d-none">
    <div
      id="luggageDetailsSec"
      class="row d-flex justify-content-center align-item-center mt-5 mb-3"
    >
      <SectionHeader :header-title="luggageDetails" />

      <div class="row d-flex justify-content-center pb-1">
        <div class="container-fluid-lg">
          <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
            <div class="luggageDetailsDiv">
              <div>
                <div class="col-lg-12 col-sm-12">
                  <table
                    class="table table-borderless"
                    :dir="!isLanguageEN ? 'rtl' : ''"
                  >
                    <thead>
                      <tr>
                        <th class="opacity-50 text-start lk" scope="col">
                          {{ luggageDetails.lType }}
                        </th>
                        <th class="opacity-50 text-start lk" scope="col">
                          {{ luggageDetails.luggage_Fee }}
                        </th>
                        <th class="opacity-50 text-center" scope="col">
                          {{ luggageDetails.lQTY }}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="luggageDetailsTableBody" v-if="!isLuggageLoader">
                      <tr v-for="(luggage, index) of luggageData" :key="index">
                        <td
                          class="col-lg-6 text-lg-start text-sm-end"
                          data-label="Luggage Type"
                          scope="row"
                        >
                          <!-- <div>
                            <img
                              v-if="!isLanguageEN"
                              :class="
                                index === 2 || index === 3 || index === 4
                                  ? `desIconImg${index} text-end`
                                  : 'ms-3 text-end'
                              "
                              :src="errorIcon"
                              alt=""
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              data-bs-html="true"
                              :title="
                                'Height : ' +
                                luggage.luggageHeight +
                                '<br>' +
                                'Width : ' +
                                luggage.luggageWidth +
                                '<br>' +
                                'Depth : ' +
                                luggage.luggageDepth +
                                '<br>' +
                                'Total Cubic : ' +
                                luggage.luggageTotalCubic +
                                '<br>'
                              "
                            />
                            <label>
                              {{ luggage.luggageDescription }}
                            </label>
                            <img
                              v-if="isLanguageEN"
                              :class="
                                index === 2 || index === 3 || index === 4
                                  ? `desIconImg${index} text-end`
                                  : 'ms-3 text-end'
                              "
                              :src="errorIcon"
                              alt=""
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              data-bs-html="true"
                              :title="
                                'Height : ' +
                                luggage.luggageHeight +
                                '<br>' +
                                'Width : ' +
                                luggage.luggageWidth +
                                '<br>' +
                                'Depth : ' +
                                luggage.luggageDepth +
                                '<br>' +
                                'Total Cubic : ' +
                                luggage.luggageTotalCubic +
                                '<br>'
                              "
                            />
                           
                          </div> -->
                          <p id="luggageID" hidden>{{ luggage.luggageID }}</p>
                          <p id="luggageCode">
                            <img
                              v-if="!isLanguageEN"
                              :class="
                                index === 2 || index === 3 || index === 4
                                  ? `desIconImg${index} text-end `
                                  : 'ms-3 text-end'
                              "
                              :src="errorIcon"
                              alt=""
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              data-bs-html="true"
                              :title="
                                'Height : ' +
                                luggage.luggageHeight +
                                '<br>' +
                                'Width : ' +
                                luggage.luggageWidth +
                                '<br>' +
                                'Depth : ' +
                                luggage.luggageDepth +
                                '<br>' +
                                'Total Cubic : ' +
                                luggage.luggageTotalCubic +
                                '<br>'
                              "
                            />
                            {{ luggage.laggageDescription }}
                            <img
                              v-if="isLanguageEN"
                              :class="
                                index === 2 || index === 3 || index === 4
                                  ? `desIconImg${index} text-end`
                                  : 'ms-3 text-end'
                              "
                              :src="errorIcon"
                              alt=""
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              data-bs-html="true"
                              :title="
                                'Height : ' +
                                luggage.luggageHeight +
                                '<br>' +
                                'Width : ' +
                                luggage.luggageWidth +
                                '<br>' +
                                'Depth : ' +
                                luggage.luggageDepth +
                                '<br>' +
                                'Total Cubic : ' +
                                luggage.luggageTotalCubic +
                                '<br>'
                              "
                            />
                          </p>
                        </td>
                        <td class="col-lg-6" data-label="Luggage Fee">
                          <div class="col-lg-8 mt-2 mt-lg-1 mt-md-0 mt-sm-0">
                            {{
                              selectedCurrency + " " + luggage.fee.toFixed(2)
                            }}
                          </div>
                        </td>
                        <td class="col-lg-6" data-label="Qty">
                          <div class="col-lg-8 m-auto">
                            <input
                              :class="`form-control ${
                                errors.luggageQty ? 'is-invalid' : ''
                              }`"
                              min="0"
                              type="number"
                              v-bind:value="luggage.luggageQty"
                              @keyup="checkMinusQTYValues($event, index)"
                            />
                            <small
                              id="lNameError"
                              class="
                                d-block
                                text-danger
                                form-text
                                invalid-feedback
                              "
                              >{{ errors.luggageQty }}</small
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    id="loading"
                    class="d-flex justify-content-center"
                    v-if="isLuggageLoader"
                  >
                    <DoubleBounce></DoubleBounce>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-12 mt-2"
                :dir="!isLanguageEN ? 'rtl' : ''"
              >
                <!-- <div>
                <ButtonList
                  :save="setLuggageDetailsToStorage"
                  :next="luggageDetails.nextStep"
                  :previous="luggageDetails.previous"
                  :buttonName="buttonName"
                />
              </div> -->
                <div class="row mt-4 pb-lg-4 mx-0">
                  <div
                    class="
                      col-6
                      row
                      flex-row
                      d-flex
                      justify-content-lg-end
                      justify-content-md-end
                      justify-content-sm-center
                      ps-0
                      mx-0
                    "
                  >
                    <div
                      class="
                        form-group
                        w-100
                        row
                        flex-row
                        d-flex
                        justify-content-lg-end
                        justify-content-md-end
                        justify-content-sm-center
                        mx-0
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-previous"
                        @click="previousView"
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  <div
                    class="
                      col-6
                      row
                      flex-row
                      d-flex
                      justify-content-lg-start
                      justify-content-md-start
                      justify-content-sm-center
                      ps-0 ps-md-3 ps-lg-3
                      mx-0
                    "
                  >
                    <div
                      class="
                        form-group
                        w-100
                        row
                        flex-row
                        d-flex
                        justify-content-lg-start
                        justify-content-md-start
                        justify-content-sm-center
                        mx-0
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-next"
                        @click="setLuggageDetailsToStorage"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="luggagePhotosSec"
      class="d-none row d-flex justify-content-center pb-1 mt-4"
    >
      <div class="container-fluid-lg">
        <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
          <div class="px-lg-4 mt-5" :dir="!isLanguageEN ? 'rtl' : ''">
            <div class="d-flex justify-content-center align-item-center">
              <div class="col-lg-12 col-sm-12">
                <h4 class="text-center mt-4 m-b-4 captionText">
                  <b>{{ luggagePhotos.headerTitle }}</b>
                </h4>
              </div>
            </div>
            <div class="luggagePhotosDiv">
              <div class="d-flex justify-content-center align-item-center mt-5">
                <div class="col-lg-12 col-sm-12 pb-4">
                  <p class="text-center">
                    You have
                    {{
                      savedData.length == 1
                        ? savedData.length + " baggage"
                        : savedData.length + " baggages"
                    }}
                  </p>
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="opacity-50 text-start" scope="col">
                          {{ luggagePhotos.lType }}
                        </th>
                        <th class="opacity-50 text-center" scope="col">
                          {{ luggagePhotos.lNumber }}
                        </th>
                        <th class="opacity-50 text-center" scope="col">
                          {{ luggagePhotos.lXxx }}
                        </th>
                        <th class="opacity-50 text-center" scope="col">
                          {{ luggagePhotos.lPhoto }}
                        </th>
                        <th class="opacity-50 text-center" scope="col">
                          {{ luggagePhotos.luggageWeight }}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="lugPhotoTBody">
                      <tr
                        v-for="(luggage, index) of savedData"
                        :key="luggage.luggageNumber"
                      >
                        <!-- <td>123</td> -->
                        <td
                          class="text-lg-start text-md-start text-sm-end"
                          data-label="Luggage Type"
                          scope="row"
                        >
                          <!-- <span>X</span> -->
                          <p
                            class="mt-4 mt-lg-4 mt-md-4 mt-sm-0 luggageElements"
                          >
                            {{ luggage.luggageDescription }}
                          </p>
                        </td>
                        <td
                          class="text-lg-center text-md-center text-sm-end"
                          data-label="Luggage Number"
                        >
                          <p
                            class="mt-4 mt-lg-4 mt-md-4 mt-sm-0 luggageElements"
                          >
                            {{ luggage.luggageNumber }}
                          </p>
                        </td>
                        <td
                          class="
                            text-lg-center text-sm-end
                            luggageuploderElements
                            imgUploaderTd
                          "
                          data-label="Handling instructions"
                        >
                          <div class="form-group mt-3">
                            <select
                              aria-describedby="dropOffLocationError"
                              class="form-select tblInput"
                              @change="
                                setLuggageXxx($event, luggage.luggageNumber)
                              "
                            >
                              <option
                                v-for="(
                                  luggageImage, index
                                ) of handlingInstructions"
                                :key="index"
                                :value="luggageImage.handlingID"
                              >
                                {{ luggageImage.handlingDescription }}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td
                          class="text-lg-center text-md-center text-sm-end"
                          data-label="Luggage Photo"
                          style="cursor: pointer"
                        >
                          <div
                            class="d-flex justify-content-around photoUploader"
                          >
                            <img
                              id="luggagePreview"
                              alt=""
                              :src="
                                luggage.luggageImageURL !== ''
                                  ? luggage.luggageImageURL
                                  : 'http://user.myportersa.com/img/imgIcon.25d28dca.svg'
                              "
                              width="30"
                              name="luggagePreview"
                              height="20"
                              class="mt-lg-2"
                            />
                            <div class="col-7">
                              <div
                                :id="luggage.luggageNumber"
                                class="passportImg form-control tblInput"
                                required
                                type="text"
                                style="cursor: pointer"
                                @click="
                                  setLuggagePhoto(index, luggage.luggageNumber)
                                "
                              >
                                Upload
                              </div>
                            </div>
                            <img
                              class="img-remove-btn"
                              @click="
                                deleteLuggageImage(
                                  $event,
                                  luggage.luggageNumber
                                )
                              "
                              :src="deleteIcon"
                              alt=""
                            />
                          </div>
                        </td>
                        <td
                          class="text-lg-center text-sm-end .imgUploaderTd"
                          data-label="luggage weight (optional)"
                        >
                          <input
                            :class="`form-control d-inline mt-3 mp-le-input ${
                              errors.luggageWeight ? 'is-invalid' : ''
                            }`"
                            min="0"
                            type="number"
                            v-bind:value="luggage.luggageWeight"
                            @keyup="checkMinusValues($event, index)"
                          />
                          <small
                            id="lNameError"
                            class="
                              d-inline
                              text-danger
                              form-text
                              invalid-feedback
                            "
                            >{{ errors.luggageWeight }}</small
                          >
                          <button
                            type="button"
                            :class="
                              !isLanguageEN
                                ? ' AR-lang-btn-delete  btn  btn-sm d-inline ms-2 btn-delete-luggage'
                                : ' btn btn-danger btn-sm d-inline ms-2 btn-delete btn-delete-luggage'
                            "
                            @click="
                              removeLuggage(
                                luggage.luggageNumber,
                                luggage.luggageDescription
                              )
                            "
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- <ButtonList
              :save="showVerification"
              :next="luggagePhotos.nextStep"
              :previous="luggagePhotos.previous"
              :buttonName="buttonName"
            /> -->
            <div class="row mt-4 pb-lg-4 mx-0">
              <div
                class="
                  col-6
                  row
                  flex-row
                  d-flex
                  justify-content-lg-end
                  justify-content-md-end
                  justify-content-sm-center
                  ps-0
                  mx-0
                "
              >
                <div
                  class="
                    form-group
                    w-100
                    row
                    flex-row
                    d-flex
                    justify-content-lg-end
                    justify-content-md-end
                    justify-content-sm-center
                    mx-0
                  "
                >
                  <button
                    type="button"
                    class="btn btn-previous"
                    @click="previousView1"
                  >
                    Previous
                  </button>
                </div>
              </div>
              <div
                class="
                  col-6
                  row
                  flex-row
                  d-flex
                  justify-content-lg-start
                  justify-content-md-start
                  justify-content-sm-center
                  ps-0 ps-md-3 ps-lg-3
                  mx-0
                "
              >
                <div
                  class="
                    form-group
                    w-100
                    row
                    flex-row
                    d-flex
                    justify-content-lg-start
                    justify-content-md-start
                    justify-content-sm-center
                    mx-0
                  "
                >
                  <button
                    type="button"
                    class="btn btn-next"
                    @click="showAddtionalServices"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="addtionalServicesSec"
      class="d-none row d-flex justify-content-center pb-1 mt-4"
    >
      <div class="container-fluid-lg mt-lg-2">
        <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
          <div class="px-lg-4 mt-5">
            <div class="d-flex justify-content-center align-item-center">
              <div class="col-lg-12 col-sm-12">
                <h4 class="text-center mt-4 m-b-4 captionText">
                  <b>{{ luggagePhotos.addtionalServices }}</b>
                </h4>
              </div>
            </div>
            <div class="d-flex justify-content-center align-item-center mt-5">
              <div class="col-lg-6 col-sm-12">
                <div
                  class="form-check mb-3"
                  v-for="(addtionalServicesRow, index) of addtionalServices"
                  :key="index"
                >
                  <div class="row">
                    <div class="col-md-8">
                      <input
                        :id="index + 1"
                        class="form-check-input"
                        name="addtionalServicesAgree"
                        type="checkbox"
                        :value="addtionalServicesRow.id"
                        @click="selectAddtionalServices()"
                      />
                      <label
                        class="form-check-label verificationLabel"
                        for="02"
                      >
                        {{ addtionalServicesRow.serviceName }}
                      </label>
                    </div>
                    <div class="col-md-4">
                      <label
                        class="
                          form-check-label
                          verificationLabel
                          d-flex
                          justify-content-end
                        "
                        for="02"
                      >
                        {{
                          selectedCurrency +
                          " " +
                          addtionalServicesRow.fee.toFixed(2)
                        }}
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
                <!-- <div class="form-check mb-5">
                  <input
                    id="all"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    @click="selectAllVerification()"
                  />
                  <label class="form-check-label verificationLabel" for="all">
                    Select All
                  </label>
                </div> -->
              </div>
            </div>
            <div
              id="showConfirmButtonGroup"
              :class="checkEmailConfirmation ? 'd-none' : 'd-block'"
              :dir="!isLanguageEN ? 'rtl' : ''"
            >
              <!-- <ButtonList
                :luggageProp="actionName"
                :next="verification.nextStep"
                :previous="verification.previous"
                :buttonName="buttonName"
                :save="showComfirmation"
              /> -->
              <div class="row mt-4 pb-lg-4 mx-0">
                <div
                  class="
                    col-6
                    row
                    flex-row
                    d-flex
                    justify-content-lg-end
                    justify-content-md-end
                    justify-content-sm-center
                    ps-0
                    mx-0
                  "
                >
                  <div
                    class="
                      form-group
                      w-100
                      row
                      flex-row
                      d-flex
                      justify-content-lg-end
                      justify-content-md-end
                      justify-content-sm-center
                      mx-0
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-previous"
                      @click="previousView2"
                    >
                      Previous
                    </button>
                  </div>
                </div>
                <div
                  class="
                    col-6
                    row
                    flex-row
                    d-flex
                    justify-content-lg-start
                    justify-content-md-start
                    justify-content-sm-center
                    ps-0 ps-md-3 ps-lg-3
                    mx-0
                  "
                >
                  <div
                    class="
                      form-group
                      w-100
                      row
                      flex-row
                      d-flex
                      justify-content-lg-start
                      justify-content-md-start
                      justify-content-sm-center
                      mx-0
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-next"
                      @click="showVerification"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="verificationSec"
      class="d-none row d-flex justify-content-center pb-1"
    >
      <div class="container-fluid-lg mt-lg-2">
        <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
          <div class="px-lg-4 mt-5">
            <div class="d-flex justify-content-center align-item-center">
              <div class="col-lg-12 col-sm-12">
                <h4 class="text-center mt-4 m-b-4 captionText">
                  <b>{{ verification.headerTitle }}</b>
                </h4>
              </div>
            </div>
            <div class="d-flex justify-content-center align-item-center mt-5">
              <div class="col-lg-6 col-sm-12">
                <div
                  class="form-check mb-3"
                  v-for="(verificationRow, index) of verification"
                  :key="index"
                >
                  <input
                    :id="index + 1"
                    class="form-check-input"
                    name="verificationAgree"
                    type="checkbox"
                    value=""
                    @click="selectVerification()"
                  />
                  <label class="form-check-label verificationLabel" for="02">
                    {{ verificationRow.packageValidationDescription }}
                  </label>
                </div>
                <hr />
                <div class="form-check mb-5">
                  <input
                    id="all"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    @click="selectAllVerification()"
                  />
                  <label class="form-check-label verificationLabel" for="all">
                    Select All
                  </label>
                </div>
              </div>

              <!-- <div class="col-lg-6 col-sm-12">
                <div
                  class="form-check mb-3"
                  v-for="(verificationRow, index) of verification"
                  :key="index"
                >
                  <input
                    id="02"
                    class="form-check-input"
                    name="verificationAgree"
                    type="checkbox"
                    value=""
                  />
                  <label class="form-check-label verificationLabel" for="02">
                    {{ verificationRow.packageValidationDescription }}
                  </label>
                </div>
              </div> -->
            </div>
            <div
              id="showConfirmButtonGroup"
              :class="checkEmailConfirmation ? 'd-none' : 'd-block'"
              :dir="!isLanguageEN ? 'rtl' : ''"
            >
              <!-- <ButtonList
                :luggageProp="actionName"
                :next="verification.nextStep"
                :previous="verification.previous"
                :buttonName="buttonName"
                :save="showComfirmation"
              /> -->
              <div class="row mt-4 pb-lg-4 mx-0">
                <div
                  class="
                    col-6
                    row
                    flex-row
                    d-flex
                    justify-content-lg-end
                    justify-content-md-end
                    justify-content-sm-center
                    ps-0
                    mx-0
                  "
                >
                  <div
                    class="
                      form-group
                      w-100
                      row
                      flex-row
                      d-flex
                      justify-content-lg-end
                      justify-content-md-end
                      justify-content-sm-center
                      mx-0
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-previous"
                      @click="previousView3"
                    >
                      Previous
                    </button>
                  </div>
                </div>
                <div
                  class="
                    col-6
                    row
                    flex-row
                    d-flex
                    justify-content-lg-start
                    justify-content-md-start
                    justify-content-sm-center
                    ps-0 ps-md-3 ps-lg-3
                    mx-0
                  "
                >
                  <div
                    class="
                      form-group
                      w-100
                      row
                      flex-row
                      d-flex
                      justify-content-lg-start
                      justify-content-md-start
                      justify-content-sm-center
                      mx-0
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-next"
                      @click="showComfirmation"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "../../../common/components/booking/SectionHeader";
import ButtonList from "../../../common/components/booking/ButtonList";
import uploadIcon from "../../../assets/Icons/upload.svg";
import errorIcon from "../../../assets/Icons/errorIcon.svg";
import deleteIcon from "../../../assets/Icons/deleteButton.svg";
import LuggageService from "../../../services/LuggageService";
import VueSweetalert2 from "sweetalert2";
import vuex from "vuex";
//importing bootstrap 5 Modules
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import Resumable from "resumablejs";
import imgIcon from "../../../assets/img/imgIcon.svg";
import { DoubleBounce } from "vue-loading-spinner";
import BookingService from "../../../services/BookingService";

export default {
  name: "LuggageDetails",
  components: { ButtonList, SectionHeader, DoubleBounce },
  data: function () {
    return {
      actionName: "save",
      buttonName: "Next Step",
      uploadIcon,
      deleteIcon,
      errorIcon,
      luggageDetails: this.$store.getters.getluggageDetails,
      luggagePhotos: this.$store.getters.getluggagePhotos,
      verification: [],
      addtionalServices: [],
      lType: "",
      lHeight: "",
      lWidth: "",
      lDepth: "",
      lTotalCubic: "",
      weight: "",
      qty: "",
      luggageData: [],
      currentLuggageDetails: [],
      savedData: [],
      img: "",
      luggageImageDetails: [],
      errors: {},
      handlingInstructions: [],
      imageUrList: [],
      imgIcon,
      checkEmailConfirmation: false,
      isLanguageEN: true,
      springLoader: false,
      isLuggageLoader: true,
      addtionalServicesIdList: [],
      selectedCurrency: "SAR",
      currencyRate: 0,
    };
  },
  async mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
    const currentCurrencyRate = JSON.parse(localStorage.getItem("CR"));

    if (currentCurrencyRate != null) {
      this.selectedCurrency = currentCurrencyRate.currency;
      this.currencyRate = currentCurrencyRate.currentRate;
    }
    const BokkingRequestDetailsList = JSON.parse(
      sessionStorage.getItem("BRDL")
    );

    const currentLocationId = sessionStorage.getItem("CID");

    if (currentLocationId) {
      if (parseInt(currentLocationId) == 3) {
        document
          .getElementById("dropOffLocationSection")
          .classList.add("d-none");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.add("d-none");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.remove("d-block");
        this.springLoader = true;
        setTimeout(() => {
          this.springLoader = false;
          document
            .getElementById("luggageDetailsSection")
            .classList.remove("d-none");
          document
            .getElementById("luggageDetailsSection")
            .classList.add("d-block");
        }, 800);
      } else if (parseInt(currentLocationId) == 4) {
        // if (
        //   BokkingRequestDetailsList != null &&
        //   BokkingRequestDetailsList.length != 0 &&
        //   BokkingRequestDetailsList[0].luggageDetails != null
        // ) {
        //   if (
        //     JSON.parse(JSON.stringify(this.getluggageInputDetails)).length > 0
        //   ) {
        //     this.setNewLuggageDetails(
        //       BokkingRequestDetailsList[0].luggageDetails
        //     );
        //   } else {
        //     this.setLuggageDetails(BokkingRequestDetailsList[0].luggageDetails);
        //   }

        //   console.log(
        //     "BokkingRequestDetailsList",
        //     BokkingRequestDetailsList[0].luggageDetails
        //   );
        // }

        document
          .getElementById("dropOffLocationSection")
          .classList.add("d-none");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.add("d-none");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.remove("d-block");

        document
          .getElementById("luggageDetailsSection")
          .classList.remove("d-none");
        document.getElementById("luggageDetailsSec").classList.add("d-none");

        this.springLoader = true;
        setTimeout(() => {
          this.springLoader = false;
          document
            .getElementById("luggagePhotosSec")
            .classList.remove("d-none");
          document.getElementById("luggagePhotosSec").classList.add("d-block");
          document
            .getElementById("luggageDetailsSection")
            .classList.add("d-block");
        }, 800);
      } else if (parseInt(currentLocationId) == 6) {
        document
          .getElementById("dropOffLocationSection")
          .classList.add("d-none");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.add("d-none");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.remove("d-block");
        document.getElementById("luggageDetailsSec").classList.add("d-none");

        this.springLoader = true;
        setTimeout(() => {
          this.springLoader = false;
          document
            .getElementById("luggageDetailsSection")
            .classList.remove("d-none");
          document;
          document
            .getElementById("luggageDetailsSection")
            .classList.add("d-block");
          document
            .getElementById("addtionalServicesSec")
            .classList.remove("d-none");
          document
            .getElementById("addtionalServicesSec")
            .classList.add("d-block");
        }, 800);
      }
    }

    await LuggageService.getHandlingInstructions()
      .then((response) => {
        this.handlingInstructions = response.data;
      })
      .catch((error) => {})
      .then(() => {
        // this.currentLuggageDetails = this.luggageData;
      });

    await LuggageService.getLuggageDetails()
      .then((response) => {
        this.isLuggageLoader = false;
        var data = response.data.map((item, index) => ({
          ...item,
          fee: parseFloat(item.fee) * parseFloat(this.currencyRate),
        }));
        // console.log("getAddtionalServices", data);
        this.luggageData = data;
      })
      .catch((error) => {})
      .then(() => {
        // this.currentLuggageDetails = this.luggageData;
      });

    await LuggageService.getValidationEnhancement()
      .then((response) => {
        this.verification = response.data;
      })
      .catch((error) => {})
      .then(() => {
        // this.currentLuggageDetails = this.luggageData;
      });
    LuggageService.getAddtionalServices()
      .then((response) => {
        var data = response.data.map((item, index) => ({
          ...item,
          fee: parseFloat(item.fee) * parseFloat(this.currencyRate),
        }));
        this.addtionalServices = data;
      })
      .catch((error) => {})
      .then(() => {
        // this.currentLuggageDetails = this.luggageData;
      });

    const allAgreement = document.getElementsByName("verificationAgree");
    document.getElementById("all").checked = true;

    for (const checkbox of allAgreement) {
      checkbox.checked = true;
    }

    const saveedLuggageImageDetails = this.getLuggageImageDetails;

    if (saveedLuggageImageDetails) {
      for (let i = 0; i < saveedLuggageImageDetails.length; i++) {
        // this.savedData = saveedLuggageImageDetails[i].luggageImages;
        // luggageImageTBody[i].children[3].children[0].children[0].src =
        //   saveedLuggageImageDetails[0].luggageImages[0].luggageImg;
      }

      setTimeout(function () {
        let luggageImageTBody = $("#lugPhotoTBody tr");

        for (let i = 0; i < saveedLuggageImageDetails.length; i++) {
          luggageImageTBody[i].children[3].children[0].children[0].src =
            saveedLuggageImageDetails[i].luggageImages[i].luggageImg;
          luggageImageTBody[i].children[4].children[0].checked =
            saveedLuggageImageDetails[i].luggageImages[i].insurance;
        }
      }, 1000);
    }

    //inti tooltip
    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },
  computed: {
    ...vuex.mapGetters(["getluggageInputDetails"]),
    ...vuex.mapGetters(["getLuggageImageDetails"]),
    ...vuex.mapGetters(["getEmailVerification"]),
  },
  watch() {
    window.onstorage = () => {
      // When local storage changes, dump the list to
      // the console.
      console.log(JSON.parse(window.localStorage.getItem("CL")));
    };
    getEmailVerification: () => {
      if (this.getEmailVerification) {
        this.checkEmailConfirmation = this.getEmailVerification;
      }
    };
  },

  methods: {
    ...vuex.mapActions(["setLuggageDetails"]),
    ...vuex.mapActions(["setAdditionalServices"]),
    ...vuex.mapActions(["setVerifyLuggageImage"]),
    ...vuex.mapActions(["setNewLuggageDetails"]),

    previousView() {
      document
        .getElementById("luggageDetailsSection")
        .classList.remove("d-block");
      document.getElementById("luggageDetailsSection").classList.add("d-none");

      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.add("d-block");
        document
          .getElementById("pickUpLocationSectionDiv")
          .classList.remove("d-none");
      }, 800);
    },

    previousView1() {
      document.getElementById("luggagePhotosSec").classList.remove("d-block");
      document.getElementById("luggagePhotosSec").classList.add("d-none");

      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document.getElementById("luggageDetailsSec").classList.add("d-block");
        document.getElementById("luggageDetailsSec").classList.remove("d-none");
      }, 800);
    },

    previousView2() {
      document
        .getElementById("addtionalServicesSec")
        .classList.remove("d-block");
      document.getElementById("addtionalServicesSec").classList.add("d-none");

      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document.getElementById("luggagePhotosSec").classList.add("d-block");
        document.getElementById("luggagePhotosSec").classList.remove("d-none");
      }, 800);
    },
    previousView3() {
      document.getElementById("verificationSec").classList.remove("d-block");
      document.getElementById("verificationSec").classList.add("d-none");

      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document
          .getElementById("addtionalServicesSec")
          .classList.add("d-block");
        document
          .getElementById("addtionalServicesSec")
          .classList.remove("d-none");
      }, 800);
    },
    removeLuggage(luggageNumber, luggageDescription) {
      let luggageArrayDetails = this.getluggageInputDetails;

      var luggage = this.savedData.find(
        (d) => d.luggageNumber === luggageNumber
      );
      if (luggage.luggageImageURL != null && luggage.luggageImageURL != "") {
        var fileLocation = "UserFlow";
        var name = luggage.luggageImageURL.split("/");
        var fileName = name[name.length - 1];
        BookingService.deleteFile(fileLocation, fileName).then((res) => {
          luggage.luggageImageURL = "";
        });
      }

      var deleteLuggage = this.savedData.filter(
        (item, index) => item.luggageNumber !== luggageNumber
      );

      // const BRDList = JSON.parse(sessionStorage.getItem("BRDL"));

      // if (BRDList != null) {
      //   var luggageDetailsList = BRDList.map((s) => ({
      //     ...s,
      //     luggageDetails: s.luggageDetails.map((ss) => ({
      //       ...ss,
      //       luggageImages: ss.luggageImages.filter(
      //         (item, index) => item.luggageNumber !== luggageNumber
      //       ),
      //       luggageQty: ss.luggageImages.filter(
      //         (item, index) => item.luggageNumber !== luggageNumber
      //       ).length,
      //     })),
      //   }));

      //   luggageDetailsList = luggageDetailsList.map((s) => ({
      //     ...s,
      //     luggageDetails: s.luggageDetails.filter(
      //       (item, index) => item.luggageQty !== 0
      //     ),
      //   }));
      //   sessionStorage.setItem("BRDL", JSON.stringify(luggageDetailsList));
      // }

      var luggage = luggageArrayDetails.find(
        (d) => d.luggageDescription === luggageDescription
      );
      if (luggage.luggageQty > 1) {
        luggage.luggageQty = luggage.luggageQty - 1;
      } else {
        luggageArrayDetails = luggageArrayDetails.filter(
          (d) => d.luggageDescription !== luggageDescription
        );
      }

      this.savedData = deleteLuggage;

      for (let i = 0; i < luggageArrayDetails.length; i++) {
        for (let j = 0; j < luggageArrayDetails[i].luggageImages.length; j++) {
          if (
            luggageArrayDetails[i].luggageImages[j].luggageNumber ===
            luggageNumber
          ) {
            luggageArrayDetails[i].luggageImages.splice(j, 1);
          } else {
            continue;
          }
        }
      }

      this.$store.dispatch("setLuggageImageDetails", luggageArrayDetails);
      this.setLuggageDetails(luggageArrayDetails);
      // this.checkIndeterminate();
    },

    setLuggageXxx(event, index) {
      let luggageArrayDetails = this.getluggageInputDetails;

      for (let i = 0; i < luggageArrayDetails.length; i++) {
        for (let j = 0; j < luggageArrayDetails[i].luggageImages.length; j++) {
          if (luggageArrayDetails[i].luggageImages[j].luggageNumber === index) {
            luggageArrayDetails[i].luggageImages[j].luggageXXX =
              event.target.value;
          } else {
            continue;
          }
        }
      }

      this.$store.dispatch("setLuggageImageDetails", luggageArrayDetails);
    },

    deleteLuggageImage(event, luggageNumber) {
      var luggage = this.savedData.find(
        (d) => d.luggageNumber === luggageNumber
      );
      if (luggage.luggageImageURL != null && luggage.luggageImageURL != "") {
        var fileLocation = "UserFlow";
        var name = luggage.luggageImageURL.split("/");
        var fileName = name[name.length - 1];
        BookingService.deleteFile(fileLocation, fileName).then((res) => {
          luggage.luggageImageURL = "";
        });
      }

      // this.imageUrList.splice(index, 1);

      // const currentImage =
      //   event.target.parentElement.parentElement.children[0].children[0].src;
      // if (
      //   !(
      //     currentImage === "http://localhost:8080/img/imgIcon.25d28dca.svg" ||
      //     currentImage ===
      //       "http://login.emerge.lk:9090/img/imgIcon.25d28dca.svg"
      //   )
      // ) {
      //   event.target.parentElement.parentElement.children[0].children[0].src =
      //     "http://login.emerge.lk:9090/img/imgIcon.25d28dca.svg";
      //   event.target.parentElement.children[1].children[0].value = "";
      // }
    },

    // initResumable: function (elemnt) {
    //   let portalid = 1;
    //   let projectid = 1;
    //   let dnnInstallationpath = "";
    //   // setTimeout(() => {
    //   //   const container = this.$el.querySelector('passportImg');
    //   //   container.scrollTop = container.scrollHeight;
    //   // }, 3000);

    //   let uploaderimage = document.getElementById(elemnt);

    //   // let uploaderimage = document.querySelector('passportImage01')
    //   let imagetype = ["bmp", "gif", "png", "jpeg", "JPG"];
    //   //this.resumable(imagetype, 'http://localhost:61709/api/FileUpload/upload', portalid, uploaderimage, 2, projectid,dnnInstallationpath)
    //   this.resumable(
    //     imagetype,
    //     "http://login.emerge.lk:9090/MyPorterFileUploaderAPI/api/FileUploader/Upload",
    //     portalid,
    //     uploaderimage,
    //     2,
    //     projectid,
    //     dnnInstallationpath
    //   );
    // },

    // resumable(
    //   filetype,
    //   target,
    //   portalid,
    //   element,
    //   type,
    //   projectid,
    //   dnnInstallationpath
    // ) {
    //   let self = this;
    //   let resumableObject = {
    //     target: target,
    //     query: {
    //       PortalId: portalid,
    //       ProjectId: projectid,
    //       dnnInstallationPath: dnnInstallationpath,
    //     },
    //     simultaneousUploads: 10,
    //     fileType: filetype,
    //     maxFiles: 10,
    //   };

    //   let r = new Resumable(resumableObject);
    //   r.assignBrowse(element);
    //   r.on("progress", function () {
    //     console.debug("progress");
    //   });

    //   r.on("fileAdded", function (file) {
    //     var filesize = Math.round(file.size / 1048576);
    //     if (filesize < 71) {
    //       self.isUploading = true;
    //       self.isPosted = true;
    //       if (type == 3) {
    //         r.upload();
    //         // if(gtDocsValidator(file))
    //         // {
    //         //    r.upload()
    //         // }
    //         // else{
    //         //   self.clearFileUplloadStuff();
    //         //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
    //         // }
    //       } else if (type == 1) {
    //         r.upload();
    //         //  if(gtVideoValidator(file))
    //         // {
    //         //    r.upload()
    //         // }
    //         // else{
    //         //   self.clearFileUplloadStuff();
    //         //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
    //         // }
    //       } else if (type == 2) {
    //         r.upload();
    //         // if(gtGraphicsValidator(file))
    //         // {
    //         //    r.upload()
    //         // }
    //         // else{
    //         //   self.clearFileUplloadStuff();
    //         //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
    //         // }
    //       }
    //     } else {
    //       //  self.clearFileUplloadStuff();
    //       //  self.eventBus.$emit('showMessage', 'File is too large. Please upload a file which is less than 70MB', 'warn')
    //     }
    //   });

    //   r.on("fileSuccess", function (file, message) {
    //     self.imageUrList.push(message);
    //     const str = self.savedData.find((d) => d.luggageNumber === element.id);
    //     str.luggageImageURL = message;
    //   });

    //   r.on("fileError", function (file) {});
    // },

    checkMinusQTYValues(event, index) {
      //check input value is decimal?
      const table = $("#luggageDetailsTableBody tr");

      if (event.target.value < 0) {
        this.luggageInputDetails[index].luggageQty = 0;
      }

      if (parseInt(event.target.value.split("")[0]) === 0) {
        event.target.value = event.target.value.split("")[1];
      }

      // table[index].children[1].children[0].children[0].value = '';
      if (event.target.value < 0 || event.target.value === "-0") {
        table[index].children[2].children[0].children[1].textContent = this
          .isLanguageEN
          ? "Please enter a valid value"
          : "الرجاء إدخال قيمة صالحة";
      } else {
        table[index].children[2].children[0].children[1].textContent = "";
      }

      if (event.target.value.indexOf(".") > -1) {
        table[index].children[2].children[0].children[1].textContent = this
          .isLanguageEN
          ? "Please enter a valid value"
          : "الرجاء إدخال قيمة صالحة";
      }
    },

    checkMinusValues(event, index) {
      if (parseInt(event.target.value.split("")[0]) === 0) {
        event.target.value = event.target.value.split("")[1];
      }
      const table = $("#lugPhotoTBody tr");

      table[index].children[4].children[1].textContent = "";
      if (event.target.value < 0 || event.target.value === "-0") {
        table[index].children[4].children[1].textContent = this.isLanguageEN
          ? "Please enter a valid value"
          : "الرجاء إدخال قيمة صالحة";
      } else {
        table[index].children[4].children[1].textContent = "";
      }
    },
    generateName(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    setLuggagePhoto(index, luggageNumber) {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (_this) => {
        let files = Array.from(input.files);
        // console.log(files[0]);
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("fileName", this.generateName(55));
        formData.append("fileDirectory", "UserFlow");

        BookingService.fileUpload(formData).then((res) => {
          if (res.status == 200) {
            this.savedData = this.savedData.map((item, index) => ({
              ...item,
              luggageImageURL:
                item.luggageNumber == luggageNumber
                  ? res.data
                  : item.luggageImageURL,
            }));
            // console.log("respone file upload", res);
          }
        });
      };
      input.click();
      // let luggageImageTBody = $("#lugPhotoTBody tr");
      // luggageImageTBody[
      //   index
      // ].children[3].children[0].children[1].children[0].parentElement.parentElement.children[0].src =
      //   this.imageUrList[index];
    },

    showComfirmation() {
      let check = document.getElementById("all").checked;
      if (check) {
        const allAgreement = document.getElementsByName("verificationAgree");
        let checkOnceNotAgrred = true;
        for (const checkbox of allAgreement) {
          if (!checkbox.checked) {
            checkOnceNotAgrred = false;
            break;
          }
        }
        if (checkOnceNotAgrred) {
          this.setVerifyLuggageImage(checkOnceNotAgrred);

          document.getElementById("verificationSec").classList.add("d-none");
          document
            .getElementById("verificationSec")
            .classList.remove("d-block");
          this.springLoader = true;
          setTimeout(() => {
            this.springLoader = false;

            document
              .getElementById("generalDivSection")
              .classList.remove("d-none");
            document
              .getElementById("generalDivSection")
              .classList.add("d-block");
          }, 800);

          window.location.href = "#generalDivSection";
        } else {
          VueSweetalert2.fire({
            text: this.isLanguageEN
              ? "Important: We are unable to continue with this booking if any of the items below are packed in your baggage. This is to comply with our company policies, the local authority requirements, and to ensure safety and security of all passengers. Thank you for your."
              : "هام: لا يمكننا الاستمرار في هذا الحجز إذا كان أي من العناصر الواردة أدناه معبأة في أمتعتك. وذلك امتثالاً لسياسات شركتنا ، ومتطلبات السلطة المحلية ، ولضمان سلامة وأمن جميع الركاب. شكرًا لك على لك.",
            confirmButtonColor: "#006203",
          });
        }
      } else {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = true;
        }
        VueSweetalert2.fire({
          text: this.isLanguageEN
            ? "Important: We are unable to continue with this booking if any of the items below are packed in your baggage. This is to comply with our company policies, the local authority requirements, and to ensure safety and security of all passengers. Thank you for your."
            : "هام: لا يمكننا الاستمرار في هذا الحجز إذا كان أي من العناصر الواردة أدناه معبأة في أمتعتك. وذلك امتثالاً لسياسات شركتنا ، ومتطلبات السلطة المحلية ، ولضمان سلامة وأمن جميع الركاب. شكرًا لك على لك.",
          confirmButtonColor: "#006203",
        });
      }
    },
    showAddtionalServices() {
      document.getElementById("luggagePhotosSec").classList.add("d-none");
      document.getElementById("luggagePhotosSec").classList.remove("d-block");
      this.springLoader = true;
      setTimeout(() => {
        this.springLoader = false;
        document
          .getElementById("addtionalServicesSec")
          .classList.remove("d-none");
        document
          .getElementById("addtionalServicesSec")
          .classList.add("d-block");
      }, 800);
    },
    showVerification() {
      const luggageDetails = this.getluggageInputDetails;

      let lmgUrlLink = 0;
      // old code
      // for (let i = 0; i < luggageDetails.length; i++) {
      //   for (let k = 0; k < luggageDetails[i].luggageImages.length; k++) {
      //     if (this.imageUrList[lmgUrlLink] === undefined) {
      //       luggageDetails[i].luggageImages[k].luggageImageURL = "";
      //     } else {
      //       luggageDetails[i].luggageImages[k].luggageImageURL =
      //         this.imageUrList[lmgUrlLink];
      //     }

      //     lmgUrlLink++;
      //   }
      // }

      // new code

      for (let i = 0; i < luggageDetails.length; i++) {
        for (let k = 0; k < luggageDetails[i].luggageImages.length; k++) {
          const getLuggageImage = this.savedData.find(
            (d) =>
              d.luggageNumber ===
              luggageDetails[i].luggageImages[k].luggageNumber
          );
          if (getLuggageImage) {
            luggageDetails[i].luggageImages[k].luggageImageURL =
              getLuggageImage.luggageImageURL;
          } else {
            continue;
          }

          lmgUrlLink++;
        }
      }

      if (this.savedData.length !== 0) {
        this.$store.dispatch("setLuggageImageDetails", luggageDetails);

        this.setAdditionalServices(this.addtionalServicesIdList);
        this.setLuggageDetails(luggageDetails);

        // const BRDList = JSON.parse(sessionStorage.getItem("BRDL"));

        // if (BRDList != null) {
        //   var BRDL = BRDList.map((item) => ({
        //     ...item,
        //     addtionalServices: this.addtionalServicesIdList,
        //   }));
        //   // sessionStorage.setItem("BRDL", JSON.stringify(BRDL));
        //   // sessionStorage.setItem("CID", 6);
        // }

        document.getElementById("addtionalServicesSec").classList.add("d-none");
        document
          .getElementById("addtionalServicesSec")
          .classList.remove("d-block");
        this.springLoader = true;
        setTimeout(() => {
          this.springLoader = false;
          document.getElementById("verificationSec").classList.remove("d-none");
          document.getElementById("verificationSec").classList.add("d-block");
        }, 800);
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          title: this.isLanguageEN
            ? "Add at least one luggage to continue."
            : "أضف حقيبة واحدة على الأقل للمتابعة.",
          icon: "warning",
        });
      }
    },

    displayImage(img) {
      this.img = img;
    },

    setLuggageInsuranceAggrement(event) {
      if (event.target.checked) {
        const allAgreement = document.getElementsByName("insuranceInput");
        for (const checkbox of allAgreement) {
          checkbox.checked = event.target.checked;
        }
      } else {
        const allAgreement = document.getElementsByName("insuranceInput");
        for (const checkbox of allAgreement) {
          checkbox.checked = event.target.checked;
        }
      }
    },
    selectVerification(id) {
      var checkboxesCount = document.querySelectorAll(
        'input[name="verificationAgree"]'
      );

      const allAgreement = document.getElementById("all");
      if (allAgreement.checked === true) {
        allAgreement.checked = false;
      } else {
        var checkboxes = document.querySelectorAll(
          'input[name="verificationAgree"]:checked'
        );

        if (checkboxes.length == checkboxesCount.length) {
          allAgreement.checked = true;
        }
      }
    },
    selectAddtionalServices() {
      var checkboxes = document.querySelectorAll(
        'input[name="addtionalServicesAgree"]:checked'
      );

      let selectedId = 0;
      for (let i = 0; i < checkboxes.length; i++) {
        var isExist = this.addtionalServicesIdList.some(
          (item) => item === checkboxes[i].value
        );

        if (!isExist) {
          this.addtionalServicesIdList.push(checkboxes[i].value);
        } else {
          this.addtionalServicesIdList = this.addtionalServicesIdList.filter(
            (item) => item === checkboxes[i].value
          );
        }
      }
    },
    selectAllVerification() {
      let all = document.getElementById("all");
      if (all.checked === true) {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = true;
        }
      } else {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = false;
        }
      }
    },

    setLuggageDetailsToStorage() {
      let luggageDetails = document.getElementById("luggageDetailsTableBody");

      let luggageArray = [];

      for (let i = 0; i < luggageDetails.children.length; i++) {
        let luggageData = {
          luggageID: 0,
          luggageDescription: "",
          luggageCode: "",
          luggageHeight: 0,
          luggageWidth: 0,
          luggageDepth: 0,
          luggageTotalCubic: 0,
          luggageQty: 0,
          luggageWeight: 0,
          isActive: true,
          createdDate: "2021-12-04T06:48:05.701Z",
          createdBy: 0,
          modifiedDate: "2021-12-04T06:48:05.701Z",
          luggageFee: 0,
          luggageTotalFee: 0,
          modifiedBy: 0,
          luggageImages: [],
        };

        if (
          luggageDetails.children[i].children[2].children[0].children[0]
            .value == 0
        ) {
          continue;
        } else {
          for (
            let j = 0;
            j <
            luggageDetails.children[i].children[2].children[0].children[0]
              .value;
            j++
          ) {
            const luggageImages = {
              luggageNumber: "",
              luggageXXX: "1",
              luggageImageURL: "",
              luggageIsInsurance: false,
            };

            luggageData.luggageImages.push(luggageImages);
          }
        }

        switch (i) {
          case 0:
            luggageData.luggageID = 1;
            luggageData.luggageDescription =
              this.luggageData[0].laggageDescription;
            luggageData.luggageCode = this.luggageData[0].luggageCode;
            luggageData.luggageHeight = this.luggageData[0].luggageHeight;
            luggageData.luggageWidth = this.luggageData[0].luggageWidth;
            luggageData.luggageDepth = this.luggageData[0].luggageDepth;
            luggageData.luggageTotalCubic =
              this.luggageData[0].luggageTotalCubic;
            luggageData.luggageQty = parseInt(
              luggageDetails.children[0].children[2].children[0].children[0]
                .value
            );
            luggageData.luggageWeight = this.luggageData[0].luggageWeight;
            luggageData.luggageFee = this.luggageData[0].luggageFee;
            luggageData.luggageTotalFee = this.luggageData[0].luggageTotalFee;

            for (
              let j = 0;
              j <
              luggageDetails.children[0].children[2].children[0].children[0]
                .value;
              j++
            ) {
              luggageData.luggageImages[j].luggageNumber =
                this.luggageData[0].luggageCode +
                Math.floor(Math.random() * 9999);
            }

            break;
          case 1:
            luggageData.luggageID = 2;
            luggageData.luggageDescription =
              this.luggageData[1].laggageDescription;
            luggageData.luggageCode = this.luggageData[1].luggageCode;
            luggageData.luggageHeight = this.luggageData[1].luggageHeight;
            luggageData.luggageWidth = this.luggageData[1].luggageWidth;
            luggageData.luggageDepth = this.luggageData[1].luggageDepth;
            luggageData.luggageTotalCubic =
              this.luggageData[1].luggageTotalCubic;
            luggageData.luggageQty = parseInt(
              luggageDetails.children[1].children[2].children[0].children[0]
                .value
            );
            luggageData.luggageWeight = this.luggageData[1].luggageWeight;
            luggageData.luggageFee = this.luggageData[1].luggageFee;
            luggageData.luggageTotalFee = this.luggageData[1].luggageTotalFee;
            for (
              let j = 0;
              j <
              luggageDetails.children[1].children[2].children[0].children[0]
                .value;
              j++
            ) {
              luggageData.luggageImages[j].luggageNumber =
                this.luggageData[1].luggageCode +
                Math.floor(Math.random() * 9999);
            }

            break;
          case 2:
            luggageData.luggageID = 3;
            luggageData.luggageDescription =
              this.luggageData[2].laggageDescription;
            luggageData.luggageCode = this.luggageData[2].luggageCode;
            luggageData.luggageHeight = this.luggageData[2].luggageHeight;
            luggageData.luggageWidth = this.luggageData[2].luggageWidth;
            luggageData.luggageDepth = this.luggageData[2].luggageDepth;
            luggageData.luggageTotalCubic =
              this.luggageData[2].luggageTotalCubic;
            luggageData.luggageQty = parseInt(
              luggageDetails.children[2].children[2].children[0].children[0]
                .value
            );
            luggageData.luggageWeight = this.luggageData[2].luggageWeight;
            luggageData.luggageFee = this.luggageData[2].luggageFee;
            luggageData.luggageTotalFee = this.luggageData[2].luggageTotalFee;

            for (
              let j = 0;
              j <
              luggageDetails.children[2].children[2].children[0].children[0]
                .value;
              j++
            ) {
              luggageData.luggageImages[j].luggageNumber =
                this.luggageData[2].luggageCode +
                Math.floor(Math.random() * 9999);
            }
            break;
          case 3:
            luggageData.luggageID = 4;
            luggageData.luggageDescription =
              this.luggageData[3].laggageDescription;
            luggageData.luggageCode = this.luggageData[3].luggageCode;
            luggageData.luggageHeight = this.luggageData[3].luggageHeight;
            luggageData.luggageWidth = this.luggageData[3].luggageWidth;
            luggageData.luggageDepth = this.luggageData[3].luggageDepth;
            luggageData.luggageTotalCubic =
              this.luggageData[3].luggageTotalCubic;
            luggageData.luggageQty = parseInt(
              luggageDetails.children[3].children[2].children[0].children[0]
                .value
            );
            luggageData.luggageWeight = this.luggageData[3].luggageWeight;
            luggageData.luggageFee = this.luggageData[3].luggageFee;
            luggageData.luggageTotalFee = this.luggageData[3].luggageTotalFee;

            for (
              let j = 0;
              j <
              luggageDetails.children[3].children[2].children[0].children[0]
                .value;
              j++
            ) {
              luggageData.luggageImages[j].luggageNumber =
                this.luggageData[3].luggageCode +
                Math.floor(Math.random() * 9999);
            }
            break;
          case 4:
            luggageData.luggageID = 5;
            luggageData.luggageDescription =
              this.luggageData[4].laggageDescription;
            luggageData.luggageCode = this.luggageData[4].luggageCode;
            luggageData.luggageHeight = this.luggageData[4].luggageHeight;
            luggageData.luggageWidth = this.luggageData[4].luggageWidth;
            luggageData.luggageDepth = this.luggageData[4].luggageDepth;
            luggageData.luggageTotalCubic =
              this.luggageData[4].luggageTotalCubic;
            luggageData.luggageQty = parseInt(
              luggageDetails.children[4].children[2].children[0].children[0]
                .value
            );
            luggageData.luggageWeight = this.luggageData[4].luggageWeight;
            luggageData.luggageFee = this.luggageData[4].luggageFee;
            luggageData.luggageTotalFee = this.luggageData[4].luggageTotalFee;
            for (
              let j = 0;
              j <
              luggageDetails.children[4].children[2].children[0].children[0]
                .value;
              j++
            ) {
              luggageData.luggageImages[j].luggageNumber =
                this.luggageData[4].luggageCode +
                Math.floor(Math.random() * 9999);
            }
            break;
          default:
            break;
        }

        luggageArray.push(luggageData);
      }

      if (JSON.parse(JSON.stringify(this.getluggageInputDetails)).length > 0) {
        this.setNewLuggageDetails(luggageArray);
      } else {
        this.setLuggageDetails(luggageArray);
      }
      console.log("luggageArray", luggageArray);
      if (luggageArray.length > 0) {
        document.getElementById("luggageDetailsSec").classList.add("d-none");
        // sessionStorage.setItem("CID", 3);
        // const BRDList = JSON.parse(sessionStorage.getItem("BRDL"));

        // if (BRDList != null) {
        //   var dd = BRDList.map((item) => ({
        //     ...item,
        //     luggageDetails: luggageArray,
        //   }));
        // sessionStorage.setItem("BRDL", JSON.stringify(dd));
        // sessionStorage.setItem("CID", 4);
        // }
        this.springLoader = true;

        setTimeout(() => {
          this.springLoader = false;
          document
            .getElementById("luggagePhotosSec")
            .classList.remove("d-none");
          document.getElementById("luggagePhotosSec").classList.add("d-block");
        }, 800);
      } else if (this.savedData.length > 0) {
        document.getElementById("luggageDetailsSec").classList.add("d-none");

        this.springLoader = true;

        setTimeout(() => {
          this.springLoader = false;
          document
            .getElementById("luggagePhotosSec")
            .classList.remove("d-none");
          document.getElementById("luggagePhotosSec").classList.add("d-block");
        }, 800);
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          title: this.isLanguageEN
            ? "Please enter baggage qty."
            : "الرجاء إدخال كمية الأمتعة.",
          icon: "warning",
        });
        // setTimeout(() => {
        //   VueSweetalert2.fire({
        //     toast: true,
        //     position: "top-end",
        //     showConfirmButton: false,
        //     timer: 3000,
        //     title: "Please add atleast one luggage",
        //     icon: "warning",
        //   });
        // }, 2000);
      }

      this.checkIndeterminate();
    },

    checkIndeterminate() {
      const savedData = this.getluggageInputDetails;

      let luggageImageTypes = [];

      for (let i = 0; i < savedData.length; i++) {
        for (let j = 0; j < savedData[i].luggageImages.length; j++) {
          const a = savedData[i].luggageImages[j].luggageNumber.split("");

          switch (a[0] + a[1]) {
            case "SM":
              const luggageImages = {
                luggageDescription: "Small Cabin",
                luggageNumber: savedData[i].luggageImages[j].luggageNumber,
                luggageXXX: savedData[i].luggageImages[j].luggageXXX,
                luggageImageURL: savedData[i].luggageImages[j].luggageImageURL,
                luggageIsInsurance:
                  savedData[i].luggageImages[j].luggageIsInsurance,
              };

              luggageImageTypes.push(luggageImages);
              break;
            case "LG":
              const luggageImages01 = {
                luggageDescription: "Large Cabin",
                luggageNumber: savedData[i].luggageImages[j].luggageNumber,
                luggageXXX: savedData[i].luggageImages[j].luggageXXX,
                luggageImageURL: savedData[i].luggageImages[j].luggageImageURL,
                luggageIsInsurance:
                  savedData[i].luggageImages[j].luggageIsInsurance,
              };

              luggageImageTypes.push(luggageImages01);
              break;
            case "XL":
              const luggageImages02 = {
                luggageDescription: "XL Cabin",
                luggageNumber: savedData[i].luggageImages[j].luggageNumber,
                luggageXXX: savedData[i].luggageImages[j].luggageXXX,
                luggageImageURL: savedData[i].luggageImages[j].luggageImageURL,
                luggageIsInsurance:
                  savedData[i].luggageImages[j].luggageIsInsurance,
              };

              luggageImageTypes.push(luggageImages02);
              break;
            case "ME":
              const luggageImages03 = {
                luggageDescription: "M Suitcase",
                luggageNumber: savedData[i].luggageImages[j].luggageNumber,
                luggageXXX: savedData[i].luggageImages[j].luggageXXX,
                luggageImageURL: savedData[i].luggageImages[j].luggageImageURL,
                luggageIsInsurance:
                  savedData[i].luggageImages[j].luggageIsInsurance,
              };

              luggageImageTypes.push(luggageImages03);
              break;
            case "LS":
              const luggageImages04 = {
                luggageDescription: "L Suitcase",
                luggageNumber: savedData[i].luggageImages[j].luggageNumber,
                luggageXXX: savedData[i].luggageImages[j].luggageXXX,
                luggageImageURL: savedData[i].luggageImages[j].luggageImageURL,
                luggageIsInsurance:
                  savedData[i].luggageImages[j].luggageIsInsurance,
              };

              luggageImageTypes.push(luggageImages04);
              break;
            default:
              break;
          }
        }
      }
      this.savedData = luggageImageTypes;

      // setTimeout(() => {
      //   for (let i = 0; i < luggageImageTypes.length; i++) {
      //     this.initResumable(luggageImageTypes[i].luggageNumber);
      //   }
      // }, 500);
    },
  },
};
</script>

<style scoped>
#loading {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 25%; */
}
.btn-previous {
  background: #ffffff;
  color: #595f69;
  width: 110px;
  height: 38px;
  border: 1px solid #e8e9eb;
  border-radius: 13px;
  box-shadow: none !important;
}

.btn-next {
  background: #000080;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 13px;
  box-shadow: none !important;
}
.btn-next:hover {
  color: #fff;
  background: #000080;
}
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.img-remove-btn {
  z-index: 50;
}
.desIconImg {
  margin-left: 45px;
}

.desIconImg2 {
  margin-left: 45px !important;
}

.desIconImg3 {
  margin-left: 28px !important;
}

.desIconImg4 {
  margin-left: 35px !important;
}

img[title]:hover::after {
  text-align: left;
}

.btn-delete {
  background-color: #db0e03;
  color: #ffffff;
  border-radius: 8px;
}

.AR-lang-btn-delete {
  background-color: #db0e03;
  color: #ffffff;
  border-radius: 8px;
  margin-right: 12px;
}

input:focus {
  outline: none;
  border-color: #000080;
  box-shadow: none;
}

#flexCheckIndeterminateLabel {
  color: #3f434a;
}

select:focus {
  outline: none;
  border-color: #000080;
  box-shadow: none;
}

.mp-le-input {
  width: 70px !important;
}

.verificationLabel {
  margin-top: 6px;
  margin-left: 15px;
  font-size: 15px;
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
}

input[type="number"],
input[type="text"] {
  border-radius: 10px;
}

.photoUploader {
  width: 150px;
  border: 1px solid #e8e9eb;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 13px;
}

.luggageDetailsDiv {
  padding-left: 135px;
  padding-right: 100px;
}

.luggagePhotosDiv {
  padding-left: 115px;
  padding-right: 60px;
}

.form-check-input:checked {
  background-color: #000080;
  border-color: #000080;
}

.captionText {
  color: #000080;
}

select {
  border-radius: 13px;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table th {
  height: 50px;
}

@media (min-width: 425px) and (max-width: 599px) {
  .photoUploader {
    position: relative;
    left: 51px;
    width: 137px !important;
    margin-right: 0;
  }
}

@media (min-width: 200px) and (max-width: 425px) {
  .photoUploader {
    position: relative;
    left: 51px;
    width: 137px !important;
    margin-right: 0;
  }
}
@media only screen and (max-device-width: 1024px) {
  .btn-delete-luggage {
    margin: 20px;
  }
  .luggagePhotosDiv {
    padding-left: 36px;
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .btn-delete-luggage {
    margin: 20px;
  }
  .luggagePhotosDiv {
    padding-left: 0;
    padding-right: 0;
  }

  .photoUploader {
    width: 108px;
  }

  .luggageDetailsDiv {
    padding-left: 0;
    padding-right: 0;
  }

  #flexCheckIndeterminate {
    margin-right: 15px;
  }

  #luggagePreview {
    margin-top: 8px;
  }
  .passportImg {
    padding-left: 3px;
  }
}
@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media screen and (max-width: 425px) {
  .luggageDetailsDiv {
    padding-left: 0;
    padding-right: 0;
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .luggagePhotosDiv {
    padding-left: 0;
    padding-right: 0;
  }
  .photoUploader {
    position: relative;
    left: 25px;
    width: 167px;
    margin-right: 0;
  }

  #luggagePreview {
    position: relative;
    top: 1px;
  }

  .luggageElements {
    margin-top: 0 !important;
  }

  .imgUploaderTd {
    padding-top: 25px;
  }
}
</style>
